import React from 'react';
import ReactDOM from 'react-dom';
import pageConfig from '@PROJECT_ROOT/responsiveFrontendPagesConfiguration.json';
import HomeContent from '@PROJECT_ROOT/assets/responsive-frontend/pages/Home/HomeContent';
import GlobalPageComponents from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/globalPageComponents';

const home = document.getElementById(pageConfig.homePage.id);
if (home) {
    const ssrData = window.SSR_DATA || null;
    const ssrParameters = window.SSR_PARAMETERS || null;
    const ssrSearchForm = window.SSR_SEARCH_FORM || null;
    ReactDOM.hydrate(
        <>
            <GlobalPageComponents />
            <HomeContent publicResourcesSSR={ssrData} ssrParameters={ssrParameters} ssrSearchForm={ssrSearchForm} />
        </>,
        home,
    );
}
