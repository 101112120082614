/* global zaraz */
import React from 'react';
import TextField from '@PROJECT_ROOT/assets/responsive-frontend/js/formFields/textField';
import HoneypotField from '@PROJECT_ROOT/assets/responsive-frontend/js/formFields/honeypotField';
import formInputValidator from '@PROJECT_ROOT/assets/responsive-frontend/js/services/formInputValidator';
import serviceData from '@PROJECT_ROOT/assets/responsive-frontend/js/services/mainAxiosService';
import reportService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/reportService';
import { toast } from 'react-toastify';

export default class NewsletterForm extends React.Component {
    constructor(props) {
        super(props);
        this.redirectTime = 10000;
        this.formElementRef = React.createRef();
        this.postPath = 'newsletter/subscribe-to';
        this.langISO = serviceData.getAdriaticLang();
        this.state = {
            errorValidationMsg: null,
            isSubmit: false,
            isFormValid: false,
            isPostLoading: false,
            formData: {
                email: '',
            },
        };
        this.postData = this.postData.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePostData = this.handlePostData.bind(this);
        this.redirectTo = this.redirectTo.bind(this);
        this.constructRequestBody = this.constructRequestBody.bind(this);
        this.handleFeedbackErrors = this.handleFeedbackErrors.bind(this);
    }

    handleInputChange(e) {
        e.persist();
        this.setState((prev) => ({
            formData: {
                ...prev.formData,
                [e.target.name]: e.target.value,
            },
        }));
    }

    handleSubmitForm(e) {
        e.preventDefault();
        const stateData = this.state;
        const propsData = this.props;
        if (stateData.formData.confirmEmail && stateData.formData.confirmEmail !== '') {
            return;
        }
        this.setState({ isSubmit: true });
        if (formInputValidator.isRequiredFieldsFilled(stateData.formData, propsData.data.formElements)) {
            this.setState({ isFormValid: true }, () => this.handlePostData());
        }
    }

    handlePostData() {
        const stateData = this.state;
        if (stateData.isFormValid && stateData.isSubmit) {
            this.postData(this.constructRequestBody());
        }
    }

    handleFeedbackErrors() {
        const { errorValidationMsg } = this.state;
        if (this.formElementRef.current) {
            formInputValidator.displayValidationErrorMessages(errorValidationMsg.validationErrorMessages);
        }
    }

    redirectTo(redirectURL) {
        setTimeout(() => { window.location.href = redirectURL; }, this.redirectTime);
    }

    postData(bodyData) {
        this.setState({ isPostLoading: true });
        serviceData
            .postApiData(this.postPath, bodyData)
            .then((res) => {
                formInputValidator.removeValidationMessages(this.formElementRef.current);
                this.setState({
                    errorValidationMsg: null,
                });
                if (res.status === 200) {
                    if (res.data.validationErrorMessages) {
                        this.setState({
                            errorValidationMsg: res.data,
                            isFormValid: false,
                        }, () => { this.handleFeedbackErrors(); });
                    }
                    if (res.data.status === 'failure') {
                        toast.error(res.data.message?.title);
                    } else if (res.data.status === 'success') {
                        toast.success(res.data.message?.title);
                        if (typeof zaraz !== 'undefined' && zaraz.consent) {
                            const marketing = zaraz.consent.get('KlIi');
                            if (zaraz.track && marketing) {
                                zaraz.track('HomepageNewsletterSubscribeSubmit');
                            }
                        }
                    }
                    if (res.data.redirectURL) {
                        this.redirectTo(res.data.redirectURL);
                    }
                    return;
                }
                reportService.reportError(res);
            })
            .catch((err) => {
                reportService.reportError(err);
            })
            .finally(() => {
                this.setState({
                    isPostLoading: false,
                });
            });
    }

    constructRequestBody() {
        const { formData } = this.state;
        const requestBody = {
            twoLetterISOLanguageCode: this.langISO,
            languageCode: this.langISO,
            ...formData,
        };
        return requestBody;
    }

    render() {
        const { data } = this.props;
        const stateData = this.state;
        return (
            <>
                {data && (
                    <div className="newsletterSection">
                        <div className="newsletterSectionInner">
                            <div className="newsletterSectionInner--header">
                                <h4>{data.title}</h4>
                                <p>{data.text}</p>
                            </div>
                            <form
                                className="form__wrapper"
                                ref={this.formElementRef}
                                onSubmit={this.handleSubmitForm}
                            >
                                {data.formElements.map((element, index) => {
                                    switch (element.type) {
                                        case 'emailField':
                                            return (
                                                <div className="form__article" key={index}>
                                                    <TextField
                                                        fieldData={element}
                                                        currentStateValue={stateData.formData[element.name]}
                                                        onChangeHandler={this.handleInputChange}
                                                        controlStateData={{ isSubmit: stateData.isSubmit }}
                                                        inputType="email"
                                                    />
                                                </div>
                                            );
                                        case 'honeypotField':
                                            return (
                                                <HoneypotField
                                                    key={index}
                                                    fieldData={element}
                                                    currentStateValue={stateData.formData[element.name]}
                                                    onChangeHandler={this.handleInputChange}
                                                    controlStateData={{ isSubmit: stateData.isSubmit }}
                                                />
                                            );
                                        default:
                                            return null;
                                    }
                                })}
                                <button
                                    type="submit"
                                    id="newsletterFooterYes"
                                    className="form__button form__button--primary"
                                    disabled={stateData.isPostLoading}
                                    onClick={() => {
                                        if (typeof zaraz !== 'undefined' && zaraz.consent) {
                                            const marketing = zaraz.consent.get('KlIi');
                                            if (zaraz.track && marketing) {
                                                zaraz.track('HomepageNewsletterSubscribeSubmit');
                                            }
                                        }
                                    }}
                                >
                                    {data.submitAction.title}
                                </button>
                            </form>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
