import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function SearchFormSkeletonMobile({ isHome, isPillarPage }) {
    return (
        <div className="searchFormWrap">
            <div className="mainSearchForm">
                <div className={`mainSearchFormAccordion--items ${!isPillarPage ? 'd-lg-none' : ''}`}>
                    <div className="mainSearchFormAccordion--item">
                        <div className="mainSearchFormAccordion--itemLeft">
                            <Skeleton circle={true} height={16} width={20} />
                            <Skeleton width={150} height={16} style={{ marginLeft: '8px' }} />
                        </div>
                        {!isHome && !isPillarPage && (
                            <div className="mainSearchFormAccordion--itemRight">
                                <Skeleton height={16} width={20} />
                            </div>
                        )}
                    </div>
                    <div className="mainSearchFormAccordion--item">
                        <div className="mainSearchFormAccordion--itemLeft">
                            <Skeleton circle={true} height={16} width={20} />
                            <Skeleton width={150} height={16} style={{ marginLeft: '8px' }} />
                        </div>
                        {!isHome && !isPillarPage && (
                            <div className="mainSearchFormAccordion--itemRight">
                                <Skeleton height={16} width={20} />
                            </div>
                        )}
                    </div>
                    <div className="mainSearchFormAccordion--item">
                        <div className="mainSearchFormAccordion--itemLeft">
                            <Skeleton circle={true} height={16} width={20} />
                            <Skeleton width={150} height={16} style={{ marginLeft: '8px' }} />
                        </div>
                        {!isHome && !isPillarPage && (
                            <div className="mainSearchFormAccordion--itemRight">
                                <Skeleton height={16} width={20} />
                            </div>
                        )}
                    </div>
                    {isHome || isPillarPage ? (
                        <div className="submitSearchSkeleton">
                            <Skeleton width="100%" />
                        </div>
                    ) : (
                        <>
                            <div className="mainSearchFormAccordion--close">
                                <Skeleton width={60} height={16} />
                            </div>
                            <div className="submitSearch">
                                <Skeleton width={100} height={40} />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
