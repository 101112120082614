import React, { useEffect } from 'react';

const Carousel = ({ data }) => {
    useEffect(() => {
        const checkJQuery = () => {
            if (window.$) {
                const carouselElement = $('#textCarousel');
                if (carouselElement.length && !carouselElement.hasClass('initialized')) {
                    carouselElement.carousel({
                        interval: 3000,
                    }).addClass('initialized');
                }
            } else {
                setTimeout(checkJQuery, 100);
            }
        };

        checkJQuery();

        return () => {
            const carouselElement = $('#textCarousel');
            carouselElement.carousel('dispose');
        };
    }, []);

    return (
        <>
            {data && (
                <div
                    id="textCarousel"
                    className="carousel slide textCarousel"
                    data-interval={5000}
                    data-bs-interval={5000}
                    data-mdb-ride="carousel"
                    data-touch="true"
                    data-ride="carousel"
                >
                    <div className="carousel-inner">
                        {data.items.map((item, index) => (
                            <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                <p> {item.message} </p>
                                <span> {item.author} </span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default Carousel;
