import React, { Component } from 'react';
import {
    MdKeyboardArrowRight,
    MdOutlinePercent,
    MdStar,
    MdNavigateBefore,
    MdNavigateNext,
} from 'react-icons/md';

export default class FeaturedAccommodation extends Component {
    constructor(props) {
        super(props);
        this.itemsRef = React.createRef();
        this.state = {
            isScrolled: false,
            isFullyScrolled: false,
            isScrollingAnimationOngoing: false,
        };
        this.itemWidthPx = 300;
        this.itemsGapPx = 16;
        this.itemTotalWidthPx = this.itemWidthPx + this.itemsGapPx;
        this.scrollAnimationDurationMs = 500;
    }

    componentDidMount() {
        this.setIsMobileScreen();
        window.addEventListener('resize', this.setIsMobileScreen);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setIsMobileScreen);
    }

    setIsMobileScreen = () => {
        this.isMobileScreen = window.matchMedia('(max-width: 991.98px)').matches;
    }

    easeInOut = (t) => {
        if (t < 0.5) {
            return 2 * t * t;
        }

        return -1 + (4 - 2 * t) * t;
    };

    scrollSmoothly = (type, value) => {
        const container = this.itemsRef.current;
        if (!container) return;

        const startScrollLeft = container.scrollLeft;
        let distance = 0;

        if (type === 'direction') {
            const numberOfItemsToScroll = this.isMobileScreen ? 1 : 2;
            distance = (value === 'right' ? this.itemTotalWidthPx : -this.itemTotalWidthPx) * numberOfItemsToScroll;
        } else if (type === 'position') {
            distance = value === 'start' ? -container.scrollLeft : container.scrollWidth - container.clientWidth - container.scrollLeft;
        }

        this.setState({ isScrollingAnimationOngoing: true });
        let startTime = null;
        const animateScroll = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const progress = Math.min(elapsed / this.scrollAnimationDurationMs, 1);

            container.scrollLeft = startScrollLeft + distance * this.easeInOut(progress);

            if (progress < 1) {
                requestAnimationFrame(animateScroll);
            } else {
                this.setState({
                    isScrollingAnimationOngoing: false,
                    isScrolled: container.scrollLeft > 0,
                    isFullyScrolled: Math.ceil(container.scrollLeft + container.clientWidth) >= container.scrollWidth,
                });
            }
        };

        requestAnimationFrame(animateScroll);
    };

    handleScroll = () => {
        const { isScrollingAnimationOngoing } = this.state;
        if (isScrollingAnimationOngoing) { return; }

        const itemsRef = this.itemsRef.current;
        if (!itemsRef) return;

        this.setState({
            isScrolled: itemsRef.scrollLeft > 0,
            isFullyScrolled: Math.ceil(itemsRef.scrollLeft + itemsRef.clientWidth) >= itemsRef.scrollWidth,
        });
    };

    render() {
        const { accommodation } = this.props;
        const { isScrolled, isFullyScrolled } = this.state;

        return (
            <div className={`featuredAccommodation ${accommodation.backgroundImageData ? 'featuredAccommodation--withBackground' : ''}`}>
                {accommodation.backgroundImageData && (
                    <img className="featuredAccommodation--background" src={accommodation.backgroundImageData.sourceURL} alt={accommodation.backgroundImageData.altText} />
                )}
                <a href={accommodation.hyperlink.redirectURL} title={accommodation.hyperlink.seoTitle} className="featuredAccommodation--header">
                    <h2>{accommodation.backgroundImageSourceURL && (<span>&#128293;</span>)}{accommodation.title}</h2>
                    <span className="line" />
                    <span className="featuredAccommodation--itemsTotalCount">{accommodation.totalNumberInOffer}</span>
                    <span className="featuredAccommodation--seeMore">
                        <span>{accommodation.hyperlink.redirectURLTitle}</span>
                        <MdKeyboardArrowRight />
                    </span>
                </a>
                <div className="featuredAccommodation--items-container">
                    <div ref={this.itemsRef} className="featuredAccommodation--items" onScroll={this.handleScroll}>
                        <div className="navigationButtonMobile navigationButtonMobile--left">
                            <button
                                type="button"
                                onClick={() => (isScrolled ? this.scrollSmoothly('direction', 'left') : this.scrollSmoothly('position', 'end'))}
                                aria-label="Scroll left"
                            >
                                <MdNavigateBefore />
                            </button>
                        </div>
                        <div className="navigationButtonMobile navigationButtonMobile--right">
                            <button
                                type="button"
                                onClick={() => (isFullyScrolled ? this.scrollSmoothly('position', 'start') : this.scrollSmoothly('direction', 'right'))}
                                aria-label="Scroll right"
                            >
                                <MdNavigateNext />
                            </button>
                        </div>
                        {accommodation.items.map((item, i) => (
                            <div className="featuredAccommodation--items__item" key={i}>
                                <a href={item.hyperlink.redirectURL} title={item.hyperlink.seoTitle} className="featuredAccommodation--cardWrap">
                                    <div className="featuredAccommodation--itemCard">
                                        <img
                                            loading="lazy"
                                            src={item.image.sourceURL}
                                            srcSet={
                                                [
                                                    item.image.sourceURL && `${item.image.sourceURL} 1x`,
                                                    item.imageSourceURLMediumSizeAlternative && `${item.imageSourceURLMediumSizeAlternative} 2x`,
                                                    item.imageSourceURLLargeSizeAlternative && `${item.imageSourceURLLargeSizeAlternative} 3x`,
                                                ]
                                                    .filter(Boolean)
                                                    .join(', ')
                                            }
                                            alt={item.image.altText}
                                            title={item.image.titleText}
                                        />
                                        {item.rating && (
                                            <div className="featuredAccommodation--rating">
                                                <MdStar /> {item.rating}
                                            </div>
                                        )}
                                        {item.hasActiveDiscount && (
                                            <div className="featuredAccommodation--discount">
                                                <MdOutlinePercent />
                                            </div>
                                        )}
                                        <div className="featuredAccommodation-itemTitle">
                                            <h3>{item.hyperlink.redirectURLTitle}</h3>
                                        </div>
                                    </div>
                                    <div className="card-price">
                                        <p dangerouslySetInnerHTML={{ __html: item.price }} />
                                        <MdKeyboardArrowRight />
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
