import React from 'react';
import MainTitle from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/mainTitle';

export default function AxiosError() {
    const axiosErrors = JSON.parse(document.getElementById('mainWrapper').getAttribute('data-axios'));
    return (
        <>
            {axiosErrors && (
                <div id="axiosErrorReact" className="row">
                    {axiosErrors.pageTitle && (
                        <div className="axiosError__pageTitle col-12">
                            <MainTitle title={axiosErrors.pageTitle} />
                        </div>
                    )}
                    <div className="axiosError__textWrap col-12 col-md-5">
                        <p className="axiosError__title">{axiosErrors.title}</p>
                        <p>{axiosErrors.firstText}</p>
                        <p>{axiosErrors.secondText}</p>
                    </div>
                    <div className="axiosError__svgWrap col-12 col-md-7">
                        <div className="axiosError__svg" />
                    </div>
                </div>
            )}
        </>
    );
}
