/* global zaraz */
import { MdKeyboardArrowRight } from 'react-icons/md';
import React from 'react';

export default class SpecialOffer extends React.Component {
    constructor(props) {
        super(props);

        this.itemWidth = 236;
        this.gap = 40;
        this.ref = React.createRef();
    }

    render() {
        const { data } = this.props;

        return (
            <>
                {data && (
                    <div className="specialOffer">
                        <a href={data.hyperlink.redirectURL} title={data.hyperlink.seoTitle} className="specialOffer--header">
                            <h2>{data.title}</h2>
                            <span className="line" />
                            <span className="specialOffer--seeMore">
                                <span>{data.hyperlink.redirectURLTitle}</span>
                                <MdKeyboardArrowRight />
                            </span>
                        </a>
                        <div className="specialOfferOuter dragscroll" ref={this.ref}>
                            {data.items.map((item, index) => (
                                <div className="specialOfferItem" key={index}>
                                    <a
                                        href={item.hyperlink.redirectURL}
                                        title={item.hyperlink.seoTitle}
                                        onClick={() => {
                                            if (typeof zaraz !== 'undefined' && zaraz.consent) {
                                                const marketing = zaraz.consent.get('KlIi');
                                                if (zaraz.track && marketing) {
                                                    zaraz.track('specialOffer', {
                                                        offerTitle: item.title,
                                                    });
                                                }
                                            }
                                        }}
                                    >
                                        <div className="card">
                                            <div className="card-body">
                                                <span>{item.apartmentsNumber}</span>
                                                <div>
                                                    <img loading="lazy" src={item.image.sourceURL} className="card-img-top" alt={item.image.altText} title={item.image.titleText} />
                                                    <h3 className="card-title">{item.hyperlink.redirectURLTitle}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </>
        );
    }
}
