import windowManipulationService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/windowManipulationService';
import reportService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/reportService';

const isClientSideRendering = windowManipulationService.isBrowserEnvironment();
// Naming: Služi za dohvat podataka definiranih u twigu "searchResults.html.twig" pa je naziv searchResultsWindowManipulationService,
// na isti način definirat ostale servise za manipulaciju nad window object-om.
export default class searchResultsWindowManipulationService {
    static getLocationID() {
        try {
            if (isClientSideRendering && window?.PageConfig?.locationID) {
                return window.PageConfig.locationID === '' ? null : window.PageConfig.locationID;
            }
            // Pod komentarom jer je očekivano da ne postoji locationID ako nema inputa u search formi.
            // reportService.reportError(new Error('Ne postoji window.PageConfig ili window.PageConfig.locationID u "getLocationID"'));

            return null;
        } catch (error) {
            reportService.reportError(new Error('Greška prilikom dohvata "locationID" u servisu "getLocationID":', error));
            return null;
        }
    }

    static getLocationName() {
        try {
            if (isClientSideRendering && window?.PageConfig?.locationName) {
                return window.PageConfig.locationName;
            }
            // Pod komentarom jer je očekivano da ne postoji locationName ako nema inputa u search formi.
            // reportService.reportError(new Error('Ne postoji window.PageConfig ili window.PageConfig.locationName u "getLocationName"'));

            return null;
        } catch (error) {
            reportService.reportError(new Error('Greška prilikom dohvata "locationName" u servisu "getLocationName":', error));
            return null;
        }
    }

    static getParams() {
        try {
            if (isClientSideRendering && window?.PageConfig?.params) {
                return window.PageConfig.params;
            }
            // Pod komentarom jer je očekivano da ne postoje parametri ako nema inputa u search formi.
            // reportService.reportError(new Error('Ne postoji window.PageConfig ili window.PageConfig.params u "getParams"'));

            return null;
        } catch (error) {
            reportService.reportError(new Error('Greška prilikom dohvata "params" u servisu "getParams":', error));
            return null;
        }
    }
}
