/* global zaraz */
import React from 'react';
import Skeleton from 'react-loading-skeleton/lib';
import VacationSuggestionCardSkeleton from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/vacationSuggestionCardSkeleton';
import {
    MdNavigateBefore,
    MdNavigateNext,
    MdOutlineExpandCircleDown,
} from 'react-icons/md';

export default class VacationSuggestions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isScrolled: false,
            isFullyScrolled: false,
            isScrollingAnimationOngoing: false,
        };
        this.itemWidthPx = 180;
        this.itemWidthPxMobile = 120;
        this.itemsGapPx = 16;
        this.numberOfItemsToScrollOnDesktop = 4;
        this.scrollAnimationDurationMs = 500;

        this.itemsRef = React.createRef();
    }

    componentDidMount() {
        this.setIsMobileScreen();
        window.addEventListener('resize', this.setIsMobileScreen);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setIsMobileScreen);
    }

    easeInOut = (t) => {
        if (t < 0.5) {
            return 2 * t * t;
        }

        return -1 + (4 - 2 * t) * t;
    };

    scrollSmoothly = (type, value) => {
        const container = this.itemsRef.current;
        if (!container) return;

        const startScrollLeft = container.scrollLeft;
        let distance = 0;

        if (type === 'direction') {
            distance = value === 'right' ? this.itemTotalWidthPx : -this.itemTotalWidthPx;
        } else if (type === 'position') {
            distance = value === 'start' ? -container.scrollLeft : container.scrollWidth - container.clientWidth - container.scrollLeft;
        }

        this.setState({
            isScrollingAnimationOngoing: true,
            isScrolled: container.scrollLeft + distance > 0,
            isFullyScrolled: Math.ceil(container.scrollLeft + distance + container.clientWidth) >= container.scrollWidth,
        });
        let startTime = null;
        const animateScroll = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const progress = Math.min(elapsed / this.scrollAnimationDurationMs, 1);

            container.scrollLeft = startScrollLeft + distance * this.easeInOut(progress);

            if (progress < 1) {
                requestAnimationFrame(animateScroll);
            } else {
                this.setState({
                    isScrollingAnimationOngoing: false,
                });
            }
        };

        requestAnimationFrame(animateScroll);
    };

    setIsMobileScreen = () => {
        const { isScrollingAnimationOngoing } = this.state;
        if (isScrollingAnimationOngoing) { return; }

        const isMobileScreenTemp = window.matchMedia('(max-width: 991.98px)').matches;
        if (this.isMobileScreen === isMobileScreenTemp) { return; }

        this.isMobileScreen = isMobileScreenTemp;
        this.itemsRef.current.scrollLeft = 0;
        this.itemTotalWidthPx = this.isMobileScreen ? this.itemWidthPxMobile + this.itemsGapPx : (this.itemWidthPx + this.itemsGapPx) * this.numberOfItemsToScrollOnDesktop;
    }

    handleScroll = () => {
        const { isScrollingAnimationOngoing } = this.state;
        if (isScrollingAnimationOngoing) { return; }

        const itemsRef = this.itemsRef.current;
        if (!itemsRef) { return; }

        this.setState({
            isScrolled: itemsRef.scrollLeft > 0,
            isFullyScrolled: Math.ceil(itemsRef.scrollLeft + itemsRef.clientWidth) >= itemsRef.scrollWidth,
        });
    };

    render() {
        const { data, isLoading } = this.props;
        const { isScrolled, isFullyScrolled } = this.state;

        return (
            <>
                <div className="row vacationSuggestions">
                    <div className="col-12 vacationSuggestions--header">
                        <h2>{isLoading ? <Skeleton /> : data.title}</h2>
                        <div className="line" />
                        <div className="vacationSuggestions--navigationButtons">
                            <button
                                type="button"
                                onClick={() => this.scrollSmoothly('direction', 'left')}
                                className="iconArrow iconArrow--left"
                                aria-label="Scroll left"
                                disabled={!isScrolled}
                            >
                                <MdOutlineExpandCircleDown />
                            </button>
                            <button
                                type="button"
                                onClick={() => this.scrollSmoothly('direction', 'right')}
                                className="iconArrow iconArrow--right"
                                aria-label="Scroll right"
                                disabled={isFullyScrolled}
                            >
                                <MdOutlineExpandCircleDown />
                            </button>
                        </div>
                    </div>
                    <div className="col-12 px-0">
                        <div
                            ref={this.itemsRef}
                            className="row vacationSuggestionsOuter dragscroll"
                            onScroll={this.handleScroll}
                        >
                            {isLoading ? (
                                <>
                                    <VacationSuggestionCardSkeleton />
                                    <VacationSuggestionCardSkeleton />
                                    <VacationSuggestionCardSkeleton />
                                    <VacationSuggestionCardSkeleton />
                                    <VacationSuggestionCardSkeleton />
                                    <VacationSuggestionCardSkeleton />
                                    <VacationSuggestionCardSkeleton />
                                    <VacationSuggestionCardSkeleton />
                                </>
                            ) : (
                                <>
                                    <div className="navigationButtonMobile navigationButtonMobile--left">
                                        <button
                                            type="button"
                                            onClick={() => (isScrolled ? this.scrollSmoothly('direction', 'left') : this.scrollSmoothly('position', 'end'))}
                                            aria-label="Scroll left"
                                        >
                                            <MdNavigateBefore />
                                        </button>
                                    </div>
                                    <div className="navigationButtonMobile navigationButtonMobile--right">
                                        <button
                                            type="button"
                                            onClick={() => (isFullyScrolled ? this.scrollSmoothly('position', 'start') : this.scrollSmoothly('direction', 'right'))}
                                            aria-label="Scroll right"
                                        >
                                            <MdNavigateNext />
                                        </button>
                                    </div>
                                    {data?.items?.map((item, index) => (
                                        <div
                                            className="vacationSuggestionsItem"
                                            key={index}
                                        >
                                            <a
                                                href={item.hyperlink.redirectURL}
                                                className="item-link"
                                                title={item.hyperlink.seoTitle}
                                                onClick={() => {
                                                    if (typeof zaraz !== 'undefined' && zaraz.consent) {
                                                        const marketing = zaraz.consent.get('KlIi');
                                                        if (zaraz.track && marketing) {
                                                            zaraz.track('vacationSuggestion', {
                                                                vacationTitle: item.title,
                                                            });
                                                        }
                                                    }
                                                }}
                                            >
                                                <div className="item-content">
                                                    <div className="item-body">
                                                        <div className="item-img--wrapper">
                                                            <img
                                                                loading="lazy"
                                                                src={item.image.sourceURL}
                                                                className="item-img"
                                                                alt={item.image.altText}
                                                                title={item.image.titleText}
                                                            />
                                                        </div>
                                                        <div className="item-text--wrapper">
                                                            <h3 className="item-text">
                                                                {item.hyperlink.redirectURLTitle}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
