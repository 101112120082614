import React from 'react';
import Skeleton from 'react-loading-skeleton/lib';

export default function VacationSuggestionCardSkeleton() {
    return (
        <div
            className="vacationSuggestionsItem"
        >
            <div className="card skeleton">
                <div className="card-body">
                    <div className="card-img skeleton">
                        <Skeleton />
                    </div>
                    <p className="skeleton-text">
                        <Skeleton />
                    </p>
                </div>
            </div>
        </div>
    );
}
