import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function SearchFormSkeletonDesktop() {
    return (
        <div className="searchFormWrap">
            <div className="mainSearchForm">
                <div className="inputLocationOuter d-lg-block d-none">
                    <div className="input-location-inner">
                        <Skeleton width={20} height={20} circle={true} className="input-location__icon" />
                        <div className="input-location__wrapper">
                            <Skeleton height={20} width="80%" />
                        </div>
                    </div>
                </div>
                <div className="inputLocationOuter d-lg-block d-none">
                    <div className="input-location-inner">
                        <Skeleton width={20} height={20} circle={true} className="input-location__icon" />
                        <div className="input-location__wrapper">
                            <Skeleton height={20} width="80%" />
                        </div>
                    </div>
                </div>
                <div className="inputGuestsOuter d-lg-block d-none">
                    <div className="input-guests-inner">
                        <Skeleton width={20} height={20} circle={true} className="input-guests__icon" />
                        <Skeleton height={20} width="80%" />
                    </div>
                </div>
                <div className="submitSearch d-lg-block d-none">
                    <Skeleton height={56} width={56} circle={true} />
                </div>
            </div>
        </div>
    );
}
