import GuestbookShort from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/guestbookShort';
import React from 'react';

const AboutUsShort = ({ data, testimonials, data3 }) => (
    <>
        <div className="aboutUsShortSection">
            <div className="aboutUsShortSection--top">
                {data && (
                    <div className="aboutUsShortSection--topLeft">
                        <h3 className="aboutUsShortTitle">{data.title}</h3>
                        <p className="aboutUsShortContent" dangerouslySetInnerHTML={{ __html: data.text }} />
                        <ul>
                            {data.ourValues.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className="aboutUsShortSection--topRight">
                    <GuestbookShort testimonials={testimonials} />
                </div>
            </div>
            <div className="aboutUsShortSection--bottom">
                {data3 && (
                    <div className="aboutUsShortSection--facts">
                        {data3.map((item, index) => (
                            <div className="factItem" key={index}>
                                <img loading="lazy" src={item.image.sourceURL} className="factItem--icon" alt={item.image.altText} title={item.image.titleText} />
                                <div className="factItem--body">
                                    <h4 className="factItem--title">{item.number}</h4>
                                    <p className="factItem--text">{item.title}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    </>
);

export default AboutUsShort;
