import React from 'react';
import { RiGoogleLine, RiStarFill, RiStarLine, RiHome4Line, RiWechatLine } from 'react-icons/ri';

const iconList = [RiGoogleLine, RiStarLine, RiHome4Line, RiWechatLine];

export default function UniqueValueProposition({ data }) {
    return (
        <div className="unique-value-proposition">
            {data?.map((item, index) => {
                const Icon = iconList[index] || null;

                return (
                    <div key={index} className="unique-value-proposition__item">
                        <div className="unique-value-proposition__item__icon-wrapper">
                            <Icon className="unique-value-proposition__item__icon" />
                        </div>
                        <div className="unique-value-proposition__item__content">
                            <span className="unique-value-proposition__item__content__upper-text">
                                {index === 0 && <RiStarFill className="unique-value-proposition__item__content__upper-text__star-icon" />}
                                {item.titleTranslaton}
                            </span>
                            <span className="unique-value-proposition__item__content__bottom-text">
                                {item.descriptionTranslation}
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
