import React from 'react';
import { ToastContainer } from 'react-toastify';

export default function GlobalPageComponents() {
    return (
        <>
            <ToastContainer
                autoClose={5000}
                theme="colored"
            />
        </>
    );
}
