import React from 'react';
import FeaturedAccommodation from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/featuredAccommodation';

const AccommodationWithFilters = ({ data }) => (
    <>
        {data && data.map((accommodation, index) => (
            <FeaturedAccommodation key={index} accommodation={accommodation} />
        ))}
    </>
);

export default AccommodationWithFilters;
