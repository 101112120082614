import React from 'react';
import { FaChevronRight } from 'react-icons/fa';

export default function pageTitle({ title, redirectURLTitle, redirectURL }) {
    return (
        <div className="mainTitle__wrapper">
            <h1 className="mainTitle__content">{title}</h1>
            <hr className="mainTitle__line flex-fill" />
            {redirectURLTitle && (
                <a className="mainTitle__link" href={redirectURL}>
                    <span> {redirectURLTitle} </span>
                    <span className="mainTitle__arrow">
                        <FaChevronRight />
                    </span>
                </a>
            )}
        </div>
    );
}
