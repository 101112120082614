/* global zaraz */
import HomeIntro from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/homeIntro';
import React from 'react';
import disableElementService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/disableElementService';
import serviceDataNew from '@PROJECT_ROOT/assets/responsive-frontend/js/services/mainAxiosService';
import reportService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/reportService';
import AxiosError from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/axiosError';
import pageConfig from '@PROJECT_ROOT/responsiveFrontendPagesConfiguration.json';
import windowManipulationService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/windowManipulationService';
import Loader from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/loader';
import VacationSuggestions from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/vacationSuggestions';
import AccommodationWithFilters from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/accommodationWithFilters';
import SpecialOffer from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/specialOffer';
import TopDestinations from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/topDestinations';
import NewsletterForm from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/newsletterForm';
import Loyality from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/loyality';
import AboutUsShort from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/aboutUsShort';
import SearchForm from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/searchForm';
import UniqueValueProposition from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/uniqueValueProposition';

// Između ostaloga pod komentarom zbog ovoga bug-a #57702
// import LocalStoreSearchParameters from '@PROJECT_ROOT/assets/responsive-frontend/js/services/LocalStoreSearchParameters';
import SearchFormSkeletonDesktop from '@PROJECT_ROOT/assets/responsive-frontend/loaders/SearchFormSkeleton/SearchFormSkeletonDesktop';
import SearchFormSkeletonMobile from '@PROJECT_ROOT/assets/responsive-frontend/loaders/SearchFormSkeleton/SearchFormSkeletonMobile';
import screenService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/screenService';

const isClientSideRendering = windowManipulationService.isBrowserEnvironment();

// Safari polyfill
if (isClientSideRendering && !window.requestIdleCallback) {
    window.requestIdleCallback = function requestIdleCallbackPolyfill(callback) {
        const start = Date.now();
        return setTimeout(() => {
            callback({
                didTimeout: false,
                timeRemaining() {
                    return Math.max(0, 50 - (Date.now() - start));
                },
            });
        }, 1);
    };
}

if (isClientSideRendering && !window.cancelIdleCallback) {
    window.cancelIdleCallback = function cancelIdleCallbackPolyfill(id) {
        clearTimeout(id);
    };
}

export default class HomeContent extends React.Component {
    constructor(props) {
        super(props);
        this.pathExtension = pageConfig.homePage.initialPathExtension;
        this.isInitialDesktopView = screenService.getInitialInfoIsDesktopView(
            props.temporaryParametersDataSSR?.isDesktopView,
            props.ssrParameters?.isDesktopView,
        );
        this.state = {
            isDesktopView: this.isInitialDesktopView,
            publicResources: props.temporaryPublicResourcesSSR || props.publicResourcesSSR || null,
            errorMsg: null,
            isLoading: null,
            // selectedFilters: LocalStoreSearchParameters.getAllSearchParametersStoredInLocalStorage(),
            selectedFilters: {
                location_id: '',
                from: '',
                to: '',
                p_dist: 0,
                persons_adult: 0,
                persons_0_3: 0,
                persons_4_11: 0,
                persons_12_17: 0,
                pet: 0,
                deviation: 0,
            },
        };
        this.updateSelectedFilters = this.updateSelectedFilters.bind(this);
        this.handlePageLoad = this.handlePageLoad.bind(this);
    }

    componentDidMount() {
        this.unsubscribeScreenService = screenService.subscribe((isDesktop) => {
            this.setState({ isDesktopView: isDesktop });
        });
        const { publicResources } = this.state;
        if (!publicResources) {
            this.setState({ isLoading: true });
            serviceDataNew
                .getData(this.pathExtension)
                .then((res) => {
                    if (res.status === 200) {
                        this.setState({
                            publicResources: res.data,
                            isLoading: false,
                        });
                        return;
                    }
                    reportService.reportError(res);
                })
                .catch((err) => {
                    this.setState({
                        errorMsg: err,
                        isLoading: false,
                    });
                    reportService.reportError(err);
                });
        }
        disableElementService.removeServerSideRenderClass();
        if (document.readyState === 'complete') {
            this.handlePageLoad();
        } else {
            window.addEventListener('load', this.handlePageLoad, { once: true });
        }
        window.isReactLoaded = true;
    }

    componentWillUnmount() {
        if (this.unsubscribeScreenService) this.unsubscribeScreenService();
        window.removeEventListener('load', this.handlePageLoad);
    }

    handlePageLoad = () => {
        this.handleZaraz();
    };

    /* eslint-disable class-methods-use-this */
    handleZaraz() {
        if (typeof zaraz !== 'undefined' && zaraz.consent) {
            const marketing = zaraz.consent.get('KlIi');
            if (zaraz.track && marketing) {
                zaraz.track('PageViewHomepage');
            }
        }
    }

    getFallbackSkeleton = () => {
        const { isDesktopView } = this.state;
        return (
            isDesktopView ? (
                <SearchFormSkeletonDesktop />
            ) : (
                <SearchFormSkeletonMobile isHome={true} />
            )
        );
    };

    serializeStateToQueryString() {
        const { selectedFilters } = this.state;
        const integerKeys = ['persons_0_3', 'persons_adult', 'persons_12_17', 'persons_4_11'];
        const queryString = Object.keys(selectedFilters)
            .filter((key) => {
                const value = selectedFilters[key];
                return value !== false && value !== null && value !== undefined && value !== '' && value !== 0;
            })
            .map((key) => {
                let value = selectedFilters[key];
                if (integerKeys.includes(key)) {
                    value = parseInt(value, 10);
                }
                if (Array.isArray(value) || typeof value === 'object') {
                    value = JSON.stringify(value);
                }
                return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            })
            .join('&');

        return queryString;
    }

    updateSelectedFilters(updatedFilters, redirect) {
        this.setState((prevState) => ({
            selectedFilters: {
                ...prevState.selectedFilters,
                ...updatedFilters,
            },
        }), async () => {
            const stateData = this.state;
            // LocalStoreSearchParameters.setAllSearchParametersStoredInLocalStorage(stateData.selectedFilters);
            if (redirect) {
                const encryptedParams = this.serializeStateToQueryString(stateData.selectedFilters);
                disableElementService.addDisabledClassOnBody('disableCurrentElementContent--transparent');
                serviceDataNew
                    .getData('quick-search-action-url/', `?${encryptedParams}`)
                    .then((res) => {
                        if (res.status === 200) {
                            let redirectURL = res.data.url;
                            if (res.data.locationName) {
                                const location = encodeURIComponent(res.data.locationName);
                                const locationId = encodeURIComponent(res.data.locationIdFilterValue);
                                redirectURL = `${res.data.url}&location=${location}&locationId=${locationId}`;
                            }
                            window.location.href = redirectURL;
                            return;
                        }
                        reportService.reportError(res);
                    })
                    .catch((err) => {
                        reportService.reportError(err);
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false,
                        });
                        disableElementService.removeDisabledClassOnBody('disableCurrentElementContent--transparent');
                    });
            }
        });
    }

    render() {
        const stateData = this.state;
        const { ssrSearchForm, temporarySearchFormDataSSR } = this.props;
        return (
            <>
                {stateData.isLoading && (
                    <div className="loaderContainer">
                        <Loader />
                    </div>
                )}
                {stateData.errorMsg && <AxiosError />}
                {stateData.publicResources && (
                    <>
                        <HomeIntro data={{ ...stateData.publicResources.homeIntro, quickLinks: stateData.publicResources.quickLinks }}>
                            <SearchForm
                                ssrSearchForm={ssrSearchForm || temporarySearchFormDataSSR || null}
                                update={this.updateSelectedFilters}
                                parametersBreak={false}
                                isHome={true}
                                searchFormLoader={this.getFallbackSkeleton()}
                            />
                        </HomeIntro>
                        <UniqueValueProposition data={stateData.publicResources.uniqueValueProposition} />
                        <VacationSuggestions data={stateData.publicResources.vacationSuggestions} />
                        <div className="bottomSectionsContainer">
                            <AccommodationWithFilters data={stateData.publicResources.featuredAccommodation} />
                            <SpecialOffer data={stateData.publicResources.specialOffers} />
                            <TopDestinations data={stateData.publicResources.topDestinations} />
                            <NewsletterForm data={stateData.publicResources.newsletter} />
                            <Loyality data={stateData.publicResources.loyality} />
                            <AboutUsShort
                                data={stateData.publicResources.aboutUsShort}
                                testimonials={stateData.publicResources.testimonialsSection}
                                data3={stateData.publicResources.facts}
                            />
                        </div>
                    </>
                )}
            </>
        );
    }
}
