/* global zaraz */
import React from 'react';
import { MdOutlinePlace } from 'react-icons/md';

export default class TopDestinations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
        };
        this.handleLoadMore = this.handleLoadMore.bind(this);
    }

    handleLoadMore() {
        const topDestinationsMiddle = document.getElementById('topDestinationsMiddle');
        const topDestinationsRight = document.getElementById('topDestinationsRight');
        if (topDestinationsMiddle && topDestinationsRight) {
            this.setState({ isLoaded: true });
        }
    }

    render() {
        const { data } = this.props;
        const { isLoaded } = this.state;

        return (
            <>
                {data && (
                    <div className="topDestinations">
                        <div className="col-12">
                            <h2>{data.title}</h2>
                            <h3 className="topDestinations__text">{data.text}</h3>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 topDestinationsLeft">
                            {data.leftItems.map((item, index) => (
                                <div className="cardOuter" key={index}>
                                    <a
                                        href={item.hyperlink.redirectURL}
                                        title={item.hyperlink.seoTitle}
                                        onClick={() => {
                                            if (typeof zaraz !== 'undefined' && zaraz.consent) {
                                                const marketing = zaraz.consent.get('KlIi');
                                                if (zaraz.track && marketing) {
                                                    zaraz.track('topDestination', {
                                                        destinationTitle: item.title,
                                                    });
                                                }
                                            }
                                        }}
                                    >
                                        <div className="card">
                                            <img
                                                loading="lazy"
                                                src={item.wideImage.sourceURL}
                                                className="topDestinationsLeftImage card-img"
                                                alt={item.wideImage.altText}
                                                title={item.wideImage.titleText}
                                            />
                                            <div className="card-img-overlay">
                                                <span className="cardNumber">
                                                    {item.numberOfUnits}
                                                </span>
                                                <div className="cardDetails">
                                                    <MdOutlinePlace />
                                                    <div className="cardDetailsRight">
                                                        <p>{item.hyperlink.redirectURLTitle}</p>
                                                    </div>
                                                    <p className="cardDetailsHoverText">{data.itemsSubtitleText}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                        <div className={`col-12 col-md-6 col-lg-4 topDestinationsMiddle ${isLoaded ? '' : 'd-none d-lg-block'}`} id="topDestinationsMiddle">
                            {data.middleItems.map((item, index) => (
                                <div className="cardOuter" key={index}>
                                    <a
                                        href={item.hyperlink.redirectURL}
                                        title={item.hyperlink.seoTitle}
                                        onClick={() => {
                                            if (typeof zaraz !== 'undefined' && zaraz.consent) {
                                                const marketing = zaraz.consent.get('KlIi');
                                                if (zaraz.track && marketing) {
                                                    zaraz.track('topDestination', {
                                                        destinationTitle: item.title,
                                                    });
                                                }
                                            }
                                        }}
                                    >
                                        <div className="card">
                                            <img
                                                loading="lazy"
                                                src={item.squareImage.sourceURL}
                                                className="topDestinationsMiddleImage card-img"
                                                alt={item.squareImage.altText}
                                                title={item.squareImage.titleText}
                                            />
                                            <div className="card-img-overlay">
                                                <span className="cardNumber">
                                                    {item.numberOfUnits}
                                                </span>
                                                <div className="cardDetails">
                                                    <MdOutlinePlace />
                                                    <div className="cardDetailsRight">
                                                        <p>{item.hyperlink.redirectURLTitle}</p>
                                                    </div>
                                                    <p className="cardDetailsHoverText">{data.itemsSubtitleText}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                        <div className={`col-12 col-md-6 col-lg-4 topDestinationsRight ${isLoaded ? '' : 'd-none d-md-block'}`} id="topDestinationsRight">
                            {data.rightItems.map((item, index) => (
                                <div className="cardOuter" key={index}>
                                    <a
                                        href={item.hyperlink.redirectURL}
                                        title={item.hyperlink.seoTitle}
                                        onClick={() => {
                                            if (typeof zaraz !== 'undefined' && zaraz.consent) {
                                                const marketing = zaraz.consent.get('KlIi');
                                                if (zaraz.track && marketing) {
                                                    zaraz.track('topDestination', {
                                                        destinationTitle: item.title,
                                                    });
                                                }
                                            }
                                        }}
                                    >
                                        <div className="card">
                                            <img
                                                loading="lazy"
                                                src={item.wideImage.sourceURL}
                                                className="topDestinationsRightImage card-img"
                                                alt={item.wideImage.altText}
                                                title={item.wideImage.titleText}
                                            />
                                            <div className="card-img-overlay">
                                                <span className="cardNumber">
                                                    {item.numberOfUnits}
                                                </span>
                                                <div className="cardDetails">
                                                    <MdOutlinePlace />
                                                    <div className="cardDetailsRight">
                                                        <p>{item.hyperlink.redirectURLTitle}</p>
                                                    </div>
                                                    <p className="cardDetailsHoverText">{data.itemsSubtitleText}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                        <button
                            className={`showMoreButton d-lg-none ${isLoaded ? 'd-none' : ''}`}
                            type="button"
                            onClick={this.handleLoadMore}
                        >
                            {data.loadMoreButtonText}
                        </button>
                    </div>
                )}
            </>
        );
    }
}
