/* global zaraz */
import React, { useEffect, useState } from 'react';
import {
    MdOutlineExplore,
    MdOutlineSell,
    MdOutlineHomeWork,
} from 'react-icons/md';

export default function HomeIntro({ data, children }) {
    const [innerWidth, setInnerWidth] = useState(0);
    const [innerHeight, setInnerHeight] = useState(0);
    const navbarHeight = 60;
    const desktopHomeIntroHeight = 680;
    const smallDeviceMaxWidth = 992;

    const handleResize = () => {
        if (window.innerWidth !== innerWidth) {
            setInnerHeight(window.innerHeight);
        }
        setInnerWidth(window.innerWidth);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    const trackMenuClick = (menuItemName) => {
        if (typeof zaraz !== 'undefined' && zaraz.consent) {
            const marketing = zaraz.consent.get('KlIi');
            if (zaraz.track && marketing) {
                zaraz.track('PageViewHomepage');
            }
        }
        if (typeof zaraz !== 'undefined' && zaraz.consent) {
            const marketing = zaraz.consent.get('KlIi');
            if (zaraz.track && marketing) {
                zaraz.track('quickMenuClick', {
                    menuName: menuItemName,
                });
                if (menuItemName === 'Accommodation') {
                    zaraz.track('HomepageSearchQuickLinkClick');
                }
            }
        }
    };

    return (
        <>
            <div className="home-intro" style={{ height: `${innerWidth < smallDeviceMaxWidth ? innerHeight - navbarHeight : desktopHomeIntroHeight}px` }}>
                <div className="home-intro__header-buttons dragscroll">
                    <a href={data.quickLinks[0]?.hyperlink.redirectURL} className="home-intro__header-buttons__button" onClick={() => trackMenuClick('Accommodation')}>
                        <MdOutlineExplore className="home-intro__header-buttons__button__icon" aria-label={data.quickLinks[0]?.hyperlink.seoTitle} />
                        <span className="home-intro__header-buttons__button__text">{data.quickLinks[0]?.hyperlink.redirectURLTitle}</span>
                    </a>
                    <a href={data.quickLinks[1]?.hyperlink.redirectURL} className="home-intro__header-buttons__button" onClick={() => trackMenuClick('Special Offers')}>
                        <MdOutlineSell className="home-intro__header-buttons__button__icon" aria-label={data.quickLinks[1]?.hyperlink.seoTitle} />
                        <span className="home-intro__header-buttons__button__text">{data.quickLinks[1]?.hyperlink.redirectURLTitle}</span>
                    </a>
                    <a href={data.quickLinks[2]?.hyperlink.redirectURL} className="home-intro__header-buttons__button" onClick={() => trackMenuClick('Explore Croatia')}>
                        <MdOutlineHomeWork className="home-intro__header-buttons__button__icon" aria-label={data.quickLinks[2]?.hyperlink.seoTitle} />
                        <span className="home-intro__header-buttons__button__text">{data.quickLinks[2]?.hyperlink.redirectURLTitle}</span>
                    </a>
                </div>
                <div className="home-intro__content">
                    <div className="home-intro__content__top">
                        <h1 className="home-intro__heading">{data.title}</h1>
                        {children}
                    </div>
                    <h2 className="home-intro__subheading">{data.text}</h2>
                </div>
            </div>
        </>
    );
}
