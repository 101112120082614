import React from 'react';
import TextCarousel from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/textCarousel';
import { MdFormatQuote, MdKeyboardArrowRight } from 'react-icons/md';

export default function GuestbookShort(props) {
    const propsData = props;
    return (
        <>
            <div className="usersAboutUs">
                <div className="usersAboutUsHeader">
                    <h3>{propsData.testimonials.mainTitle}</h3>
                    <MdFormatQuote />
                </div>
                <div className="usersAboutUsInner">
                    <TextCarousel data={{ items: propsData.testimonials.testimonials }} />
                </div>
                {propsData.testimonials.hyperlink && (
                    <a href={propsData.testimonials.hyperlink.redirectURL} title={propsData.testimonials.hyperlink.seoTitle}>
                        {propsData.testimonials.hyperlink.redirectURLTitle}
                        <MdKeyboardArrowRight />
                    </a>
                )}
            </div>
        </>
    );
}
