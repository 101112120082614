import MobileDetect from 'mobile-detect';
import windowManipulationService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/windowManipulationService';

export default class screenService {
    // Metoda je s namjerom nazvana ovako(getInitialInfoIsDesktopView), razlog: koristi se samo za inicijalno postavljanje
    // state-a di će se provjerit postoji li podatak o device-u sa SSR-a. Ne želimo je koristit u drugim slučajevima
    // (npr: resize) jer bi u tome slučaju uvijek vraćala "statični" podatak koji je dobila na load sa SSR-a .
    // Ako metoda ne primi parametre ili ako primljeni parametri nisu tipa boolena pozvat će se screenService.isDesktopView() kao fallback
    // i ona može odradit provjeru samo kad se radi o CSR-u, ako se radi o SSR-u metoda će vratiti da se ne radi o desktopView-u.
    static getInitialInfoIsDesktopView = (ssrTemporaryIsDesktopView, ssrPermanentIsDesktopView) => {
        if (typeof ssrTemporaryIsDesktopView === 'boolean') {
            return ssrTemporaryIsDesktopView;
        }
        if (typeof ssrPermanentIsDesktopView === 'boolean') {
            return ssrPermanentIsDesktopView;
        }

        return screenService.isDesktopView();
    };

    static getDeviceInfo() {
        if (!windowManipulationService.isBrowserEnvironment()) {
            // Ako nema mogućnost dohvata podataka iz browsera vraća da se radi o mobileVIew-u, preferiramo mobileFirst
            // razlog: https://www.geeksforgeeks.org/mobile-first-design/
            return { isMobile: true, width: 0, dpr: 1 }; // mobile u SSR-u
        }
        const md = new MobileDetect(window.navigator.userAgent);
        const isMobile = md.mobile() !== null;
        const width = window.innerWidth;
        const dpr = window.devicePixelRatio || 1;

        return { isMobile, width, dpr };
    }

    static isDesktopView() {
        const { isMobile, width, dpr } = screenService.getDeviceInfo();
        const isDesktop = !isMobile && width >= 992 && dpr <= 2;

        return isDesktop;
    }

    // Kreirana je isMobileView al mobileView je moguće zaključiti iz return-a isDesktopView
    // Stavljam je pod komentar dok se ne pojavi potreba za njenim korištenjem
    // static isMobileView() {
    //     const { isMobile, width } = screenService.getDeviceInfo();
    //     const isMobileView = isMobile || width < 992;

    //     return isMobileView;
    // }

    static subscribe(callback) {
        if (!windowManipulationService.isBrowserEnvironment()) {
            return () => {};
        }
        const handler = () => callback(screenService.isDesktopView());
        handler();
        window.addEventListener('resize', handler);

        return () => window.removeEventListener('resize', handler);
    }
}
