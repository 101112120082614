/* global zaraz */
import React from 'react';

const Loyalty = ({ data }) => {
    const handleLinkClick = (buttonText) => {
        if (typeof zaraz !== 'undefined' && zaraz.track) {
            zaraz.track('loyaltyClick', { loyaltyType: buttonText });
        }
    };

    return (
        <>
            {data && (
                <div className="loyalitySection">
                    <div className="loyalitySectionInner">
                        <div className="loyalitySectionInner--header">
                            <h4>{data.title}</h4>
                            <p>{data.text}</p>
                        </div>
                        <a
                            href={data.buttonOne.hyperlink.redirectURL}
                            title={data.buttonOne.hyperlink.seoTitle}
                            className="loyalitySectionLink loyalitySectionLink--left"
                            onClick={() => handleLinkClick(data.buttonOne.text)}
                        >
                            {data.buttonOne.hyperlink.redirectURLTitle}
                            <span>{data.buttonOne.text}</span>
                        </a>
                        <a
                            href={data.buttonTwo.hyperlink.redirectURL}
                            title={data.buttonTwo.hyperlink.seoTitle}
                            className="loyalitySectionLink loyalitySectionLink--right"
                            onClick={() => handleLinkClick(data.buttonTwo.text)}
                        >
                            {data.buttonTwo.hyperlink.redirectURLTitle}
                            <span>{data.buttonTwo.text}</span>
                        </a>
                    </div>
                </div>
            )}
        </>
    );
};

export default Loyalty;
