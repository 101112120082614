import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import backButtonService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/backButtonService';

export default function ModalControl({
    modalControlIsOpen,
    modalControlOnClose,
    modalControlDisableBodyScroll,
    modalControlDependentState,
    modalControlDependentHandler,
    animate = false,
    children,
}) {
    useEffect(() => {
        function handleBackButton() {
            if (modalControlIsOpen) {
                modalControlOnClose();
            }
        }

        if (modalControlIsOpen) {
            backButtonService.addBackButtonListener(handleBackButton);
            if (modalControlDisableBodyScroll) {
                document.body.classList.add('modalControl--disableBodyScroll');
            }
        } else {
            backButtonService.removeBackButtonListener();
            document.body.classList.remove('modalControl--disableBodyScroll');
        }

        return () => {
            backButtonService.removeBackButtonListener();
            document.body.classList.remove('modalControl--disableBodyScroll');
            if (modalControlDependentState && modalControlDependentHandler) {
                backButtonService.addBackButtonListenerWithoutCheckingPopStateHandler(modalControlDependentHandler);
            }
        };
    }, [modalControlIsOpen, modalControlDisableBodyScroll, modalControlOnClose, modalControlDependentState, modalControlDependentHandler]);

    if (!modalControlIsOpen && !animate) {
        return null;
    }

    const getClassName = () => {
        if (!animate) return '';
        return modalControlIsOpen ? 'animate-in' : 'animate-out';
    };

    return ReactDOM.createPortal(
        <div id="modalControl" className={`modal-control ${getClassName()}`} aria-hidden={!modalControlIsOpen}>
            {children}
        </div>,
        document.body,
    );
}
