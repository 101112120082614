/* global zaraz */
import 'react-dates/initialize';

import {
    HORIZONTAL_ORIENTATION,
    START_DATE,
    VERTICAL_SCROLLABLE,
} from '@PROJECT_ROOT/assets/responsive-frontend/js/services/constants';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import {
    MdOutlinePlace,
    MdOutlineCalendarToday,
    MdPersonOutline,
    MdOutlineSearch,
    MdOutlineMyLocation,
    MdNavigateBefore,
    MdNavigateNext,
    MdClose,
    MdApartment,
    MdInfoOutline,
} from 'react-icons/md';
import ModalControl from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/modalControl';
import React from 'react';
import loadable from '@loadable/component';
import { RiPencilLine } from 'react-icons/ri';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import serviceData from '@PROJECT_ROOT/assets/responsive-frontend/js/services/mainAxiosService';
import backButtonService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/backButtonService';
import reportService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/reportService';
import searchResultsWindowManipulationService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/searchResultsWindowManipulationService';
import debounce from 'lodash/debounce';
import pageConfig from '@PROJECT_ROOT/responsiveFrontendPagesConfiguration.json';

// Između ostaloga pod komentarom zbog ovoga bug-a #57702
// import LocalStoreSearchParameters from '@PROJECT_ROOT/assets/responsive-frontend/js/services/LocalStoreSearchParameters';

// Loadable komponente
const DateRangePicker = loadable(
    () => import(/* webpackChunkName: "responsiveFrontendDateRangePickerFromSearchForm" */'react-dates/lib/components/DateRangePicker')
        .catch((err) => {
            // TODO: Prebacit u service ako se pokaze korisno.
            reportService.reportError(err);
            return { default: () => '' };
        }),
);

export default class SearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.pathExtension = 'locations';
        this.staticPathExtension = pageConfig.searchFormComponent.quickSearchFormPathExtension;
        this.locationPathExtension = pageConfig.searchFormComponent.locationFormPathExtension;
        this.datePathExtension = pageConfig.searchFormComponent.datePickFormPathExtension;
        this.guestsPathExtension = pageConfig.searchFormComponent.numberOfGuestsFormPathExtension;
        this.langISO = serviceData.getAdriaticLang();
        this.numberOfMonthsDisplayedDesktop = props.isPillarPage ? 1 : 2;
        this.locationBlurTimeout = null;
        this.state = {
            isAccordionOpen: false,
            hasScrolledFromTop: false,
            isAccordionVisible: false,
            accordionUpdate: 0,
            staticPublicResources: props.ssrSearchForm?.quickSearchResponse || '',
            locationPublicResources: props.ssrSearchForm?.locationFormResponse || '',
            datePublicResources: props.ssrSearchForm?.datePickFormResponse || '',
            guestsPublicResources: props.ssrSearchForm?.numberOfGuestsFormResponse || '',
            persons: 0,
            showLocation: false,
            showDateRange: false,
            showGuests: false,
            showModal: false,
            startDate: null,
            endDate: null,
            isFlexible: false,
            flexibleDays: 1,
            numberOfDays: 0,
            calendarStartingMonth: moment().startOf('month'),
            persons_adult: 0,
            persons_0_3: 0,
            persons_4_11: 0,
            persons_12_17: 0,
            p_dist: 0,
            searchedArray: [],
            searchString: '',
            selectedLocation: null,
            selectedLocationLabel: null,
            focusedLocationIndex: null,
            locationURL: '',
            isLoading: !props.ssrSearchForm, // Ako ne postoji SSR za searchFormu(pukao ili nije proslijeđen), pali se loader dok CSR ne odradi requestove.
            isFetchingLocations: false,
            backButtonListenerAdded: false,
            showTypingLocationModal: false,
            isSmallDevice: false,
            shouldScrollToInitialMonth: true,
            dateRangePickerMobileRef: null,
        };
        this.locationInput = React.createRef();
        this.guestsInput = React.createRef();
        this.inputLocationMobileRef = React.createRef();
        this.mainSearchElementRef = React.createRef();
        this.locationDropdownDesktopRef = React.createRef();
        this.dateRangeWrapRef = React.createRef();
        this.inputDateRangeRef = React.createRef();

        this.getData = this.getData.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.handleGuests = this.handleGuests.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePdistChange = this.handlePdistChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBlurLocationsDesktop = this.handleBlurLocationsDesktop.bind(this);
        this.handleBlurGuests = this.handleBlurGuests.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleFlexible = this.handleFlexible.bind(this);
        this.confirmDates = this.confirmDates.bind(this);
        this.resetDates = this.resetDates.bind(this);
        this.showDatePicker = this.showDatePicker.bind(this);
        this.onDatesChange = this.onDatesChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.openGuestsModal = this.openGuestsModal.bind(this);
        this.openDatesModal = this.openDatesModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.selectLocation = this.selectLocation.bind(this);
        this.parseUrlParams = this.parseUrlParams.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.toggleAccordionVisibility = this.toggleAccordionVisibility.bind(this);
        this.handleLocationInputChange = this.handleLocationInputChange.bind(this);
        this.handleFocusInputLocation = this.handleFocusInputLocation.bind(this);
        this.handleSearchFormBackButton = this.handleSearchFormBackButton.bind(this);
        this.getCalendarStartingMonth = this.getCalendarStartingMonth.bind(this);
        this.handleFetchLocationData = this.handleFetchLocationData.bind(this);
        this.debouncedFetchLocationData = debounce(this.handleFetchLocationData, 300);
        this.isSearchStringAnId = this.isSearchStringAnId.bind(this);
        this.isSearchStringValid = this.isSearchStringValid.bind(this);
        this.isSelectedLocationInRecommendedLocations = this.isSelectedLocationInRecommendedLocations.bind(this);
        this.getNumberOfMonthsDisplayedMobile = this.getNumberOfMonthsDisplayedMobile.bind(this);
        this.getSelectedGuestsLabel = this.getSelectedGuestsLabel.bind(this);
    }

    componentDidMount() {
        const twoLetterISOLanguageCodeParameter = serviceData.getAdriaticLang();
        const momentLocale = twoLetterISOLanguageCodeParameter === 'no' ? 'nn' : twoLetterISOLanguageCodeParameter;
        moment.locale(momentLocale);
        if (twoLetterISOLanguageCodeParameter === 'cs') {
            moment.updateLocale('cs', {
                months: 'leden_únor_březen_duben_květen_červen_červenec_srpen_září_říjen_listopad_prosinec'.split('_'),
            });
        }
        const propsData = this.props;
        const {
            staticPublicResources,
            locationPublicResources,
            datePublicResources,
            guestsPublicResources,
        } = this.state;
        if (!staticPublicResources || !locationPublicResources || !datePublicResources || !guestsPublicResources) {
            this.getData();
        } else {
            this.setState({ calendarStartingMonth: this.getCalendarStartingMonth() });
        }
        this.parseUrlParams(() => {
            const { searchString, endDate, persons } = this.state;
            const showFirstDiv = searchString && endDate !== null && endDate !== '' && persons > 0 && !propsData.parametersBreak;
            // Stavio sam !propsData.isHome && iz razloga jer isAccordionOpen imenovana zapravo radi accordionClose
            this.setState({ isAccordionOpen: !propsData.isHome && !propsData.isPillarPage && showFirstDiv });
        });
        this.setState({ isAccordionVisible: propsData.isAccordionVisible });
        window.addEventListener('scroll', this.handleScroll);
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps) {
        const propsData = this.props;
        const stateData = this.state;
        if (stateData.dateRangePickerMobileRef && stateData.showDateRange && stateData.shouldScrollToInitialMonth) {
            const calendarMonthGridElement = stateData.dateRangePickerMobileRef.container?.querySelector('.CalendarMonthGrid__vertical_scrollable');
            const calendarNthChild = this.getNumberOfMonthsToScrollThroughInitiallyMobile() + 1;
            const calendarMonthGridItemElement = calendarMonthGridElement?.querySelector(`:scope > div:nth-child(${calendarNthChild})`);
            calendarMonthGridItemElement?.scrollIntoView({ behavior: 'smooth' });
            this.dateRangeWrapRef.current?.scrollIntoView({ behavior: 'smooth' });
            this.setState({ shouldScrollToInitialMonth: false });
        }
        if (!stateData.backButtonListenerAdded && this.mainSearchElementRef.current) {
            this.setState({ backButtonListenerAdded: true }, () => {
                backButtonService.addBackButtonListener(this.handleSearchFormBackButton);
            });
        }
        if (prevProps.isAccordionVisible !== propsData.isAccordionVisible) {
            this.setState({
                isAccordionVisible: propsData.isAccordionVisible,
            });
            if (propsData.isAccordionVisible) {
                backButtonService.addBackButtonListener(this.handleSearchFormBackButton);
            } else {
                backButtonService.removeBackButtonListener();
            }
        }
        if (
            !propsData.isHome && !propsData.isPillarPage // Stavio sam !propsData.isHome && iz razloga jer isAccordionOpen imenovana zapravo radi accordionClose
            && stateData.page > 1
            && !stateData.isAccordionOpen
            && stateData.accordionUpdate === 0
            && !propsData.parametersBreak
        ) {
            this.setState({ isAccordionOpen: true });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
        if (this.locationBlurTimeout) {
            clearTimeout(this.locationBlurTimeout);
        }
    }

    handleDateRangePickerMobileRef = (element) => {
        this.setState({ dateRangePickerMobileRef: element });
    };

    handleResize = () => {
        this.setState({ isSmallDevice: window.matchMedia('(max-width: 991.98px)').matches });
    }

    handleSearchFormBackButton() {
        const propsData = this.props;
        this.setState({ isAccordionVisible: false });
        if (propsData?.closeAccordion) {
            propsData.closeAccordion();
        }
    }

    handleScroll = () => {
        const { isAccordionVisible, hasScrolledFromTop } = this.state;
        if (!hasScrolledFromTop && window.scrollY > 0 && isAccordionVisible) {
            // TODO: Zakomentirano iz razloga sto trenutno ne zelimo zatvaranje na scroll.
            // this.toggleAccordionVisibility();
            this.setState({ hasScrolledFromTop: true });
        }
    }

    handleModal(event) {
        this.setState({ showLocation: false, showGuests: false, showDateRange: false, shouldScrollToInitialMonth: true });
        this.setState({ [event.target.name]: !event.target.value });
        const eventName = event.target.name;
        if (eventName === 'showDateRange') {
            this.setState({ focusedInput: START_DATE });
        }
    }

    handleGuests(event) {
        event.stopPropagation();
        const sData = this.state;
        const eventOperation = event.target.getAttribute('data-operation');
        const eventValue = event.target.value;
        const eventName = event.target.name;
        const eventProp = event.target.getAttribute('data-prop');

        const validateMaxGuests = (guestType, currentCount) => {
            if (sData.guestsPublicResources) {
                const maxGuests = sData.guestsPublicResources[guestType].maximumNumber;
                return currentCount <= maxGuests;
            }
            return false;
        };

        if (eventOperation === 'minus') {
            if (eventValue > 0) {
                if (sData.persons_adult > 0 && eventName === 'persons_adult') {
                    const aTemp = Number(eventValue) - 1;
                    const aTempTotal = Number(sData.persons) - 1;
                    this.setState({ persons_adult: aTemp, persons: aTempTotal }, () => this.pdistOnWhenMoreThan6GuestsAndUserDidntTurnOffPdist('minus'));
                } else {
                    const temp = Number(eventValue) - 1;
                    const tempTotal = Number(sData.persons) - 1;
                    if (eventName === 'pet') {
                        this.setState({ [eventName]: temp }, () => this.pdistOnWhenMoreThan6GuestsAndUserDidntTurnOffPdist('minus'));
                    } else {
                        this.setState({ [eventName]: temp, persons: tempTotal }, () => this.pdistOnWhenMoreThan6GuestsAndUserDidntTurnOffPdist('minus'));
                    }
                }
            }
        } else {
            let tempTotal1 = Number(sData.persons);
            if (sData.persons_adult === 0 && eventName !== 'persons_adult') {
                this.setState({ persons_adult: 1 });
                tempTotal1 += 1;
            }
            const temp1 = Number(eventValue) + 1;
            if (validateMaxGuests(eventProp, temp1)) {
                if (eventName !== 'pet') {
                    tempTotal1 += 1;
                }
                this.setState({ [eventName]: temp1, persons: tempTotal1 }, () => this.pdistOnWhenMoreThan6GuestsAndUserDidntTurnOffPdist('plus'));
            }
        }
    }

    handleInputChange() {
        const stData = this.state;
        if (stData.isFlexible === false) {
            this.setState({ isFlexible: true });
        } else {
            this.setState({ isFlexible: false });
        }
    }

    handlePdistChange() {
        const stateData = this.state;
        if (stateData.p_dist === 0) {
            this.setState({ p_dist: 1, userTouchedPdist: true });
        } else {
            this.setState({ p_dist: 0, userTouchedPdist: true });
        }
        this.guestsInput.current.focus();
    }

    handleLocationInputChange(event) {
        const location = event.target.value;
        this.setState({ searchString: location, selectedLocation: null, selectedLocationLabel: null, locationURL: '', focusedLocationIndex: null }, () => {
            this.setState({ isFetchingLocations: this.isSearchStringValid() }, () => {
                this.debouncedFetchLocationData();
            });
        });
    }

    handleFetchLocationData() {
        const { searchString, selectedLocation, locationPublicResources: { recommendedLocations } } = this.state;
        if (!this.isSearchStringValid()) {
            this.fetchLocationsAbortController?.abort();
            this.setState({ searchedArray: [], isFetchingLocations: false });
            return;
        }
        this.fetchLocationsAbortController = new AbortController();
        serviceData
            .postApiDataWithConfig(`locations/autocomplete/${this.langISO}`, { query: searchString }, { signal: this.fetchLocationsAbortController.signal })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        this.setState({
                            searchedArray: res.data,
                            focusedLocationIndex: 0,
                        });
                    } else {
                        let selectedLocationIndex = -1;
                        if (selectedLocation !== null) {
                            try {
                                selectedLocationIndex = recommendedLocations.findIndex((location) => location.title === selectedLocation);
                            } catch (err) {
                                reportService.reportError(err);
                            }
                        }
                        this.setState({
                            searchedArray: [],
                            focusedLocationIndex: selectedLocationIndex !== -1 ? selectedLocationIndex : null,
                        });
                    }
                    return;
                }
                reportService.reportError(res);
            })
            .catch((err) => {
                reportService.reportError(err);
            })
            .finally(() => {
                this.setState({ isFetchingLocations: false });
            });
    }

    handleChange(event) {
        const { dataset: name, value } = event.target;
        this.setState({ [name]: value, searchString: value, locationURL: '', selectedLocation: null, selectedLocationLabel: null });
    }

    handleBlurGuests(event) {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            this.setState({ showGuests: false });
        }
    }

    handleBlurLocationsDesktop(event) {
        const { currentTarget, relatedTarget } = event;
        if (this.locationBlurTimeout) {
            clearTimeout(this.locationBlurTimeout);
            this.locationBlurTimeout = null;
        }
        // Postoji timeout na ovoj funckiji jer nije poželjno da se izvrši prije selectLocation funkcije koja se poziva na klik
        this.locationBlurTimeout = setTimeout(() => {
            const { selectedLocation, focusedLocationIndex, searchedArray, locationPublicResources: { recommendedLocations, withoutLocationLabel } } = this.state;
            if (!currentTarget.contains(relatedTarget)) {
                this.setState({ showLocation: false });
            }
            if (focusedLocationIndex === null || selectedLocation !== null) return;
            const locationItemId = searchedArray.length > 0 ? searchedArray[focusedLocationIndex].id : recommendedLocations[focusedLocationIndex]?.value ?? '';
            const locationItemText = searchedArray.length > 0 ? searchedArray[focusedLocationIndex].value : recommendedLocations[focusedLocationIndex]?.title ?? withoutLocationLabel;
            const locationItemLabel = searchedArray.length > 0 ? searchedArray[focusedLocationIndex].label : recommendedLocations[focusedLocationIndex]?.title ?? withoutLocationLabel;
            this.setState(() => ({
                locationURL: locationItemId,
                searchString: locationItemText,
                selectedLocation: locationItemText,
                selectedLocationLabel: locationItemLabel,
            }));
        }, 100);
    }

    handleFocus(event) {
        event.persist();
        const { currentTarget } = event;
        this.setState({ [currentTarget.getAttribute('data-modal')]: true });
        setTimeout(() => {
            const scrollOffset = currentTarget.getBoundingClientRect().top + window.scrollY - 150;
            window.scrollTo({ top: scrollOffset, behavior: 'smooth' });
        }, 100);
    }

    handleFocusInputLocation() {
        const { showTypingLocationModal } = this.state;
        if (!showTypingLocationModal) {
            this.setState({ showTypingLocationModal: true });
        }
    }

    handleFlexible(event) {
        const flexData = this.state;
        const operation = event.target.getAttribute('data-operation');
        this.setState({ numberOfDays: 1 });
        flexData.numberOfDays = Math.ceil(flexData.endDate.diff(flexData.startDate) / (1000 * 60 * 60 * 24));
        if (flexData.numberOfDays > 30) {
            this.setState({ numberOfDays: 30 });
        }
        if (flexData.numberOfDays > 7 && flexData.flexibleDays > 7 && !(operation === '-' || operation === '+')) {
            this.setState({ flexibleDays: 7 });
        }
        if (operation === '-' && flexData.flexibleDays > 1) {
            const daysMinus = flexData.flexibleDays - 1;
            this.setState({ flexibleDays: daysMinus });
        }
        if (operation === '+') {
            if (flexData.flexibleDays < flexData.numberOfDays - 1 && flexData.flexibleDays < 30 && flexData.numberOfDays > 2) {
                const daysPlus = flexData.flexibleDays + 1;
                this.setState({ flexibleDays: daysPlus });
            }
        }
    }

    handleSubmit() {
        const submitData = this.state;
        const { updateSelectedFilters } = this.props;
        // const submitFetchData = this.props;
        // const URLOne = submitFetchData.data.submitURL;
        // let URLTwo = submitData.locationURL;
        // let locationParam = submitData.locationURL;
        // if (!URLTwo) { locationParam = submitFetchData.data.allCroatiaURL; URLTwo = submitFetchData.data.allCroatiaURL; }
        // const from = moment(submitData.startDate).format('YYYY-MM-DD');
        // const to = moment(submitData.endDate).format('YYYY-MM-DD');
        // const persons = submitData.persons;
        // const pdist = submitData.p_dist;
        // const deviation = submitData.flexibleDays;
        // const flexible = submitData.isFlexible;
        // const adults = submitData.persons_adult;
        // const kids3 = submitData.persons_0_3;
        // const kids11 = submitData.persons_4_11;
        // const kids17 = submitData.persons_12_17;
        // const pets = submitData.pets_no;
        // let fullURL = window.location.origin;
        // fullURL += '/';
        // fullURL += document.documentElement.lang;
        // fullURL += '/';
        // fullURL += URLOne;
        // fullURL += '/';
        // fullURL += URLTwo;
        // const params = [];

        // if (submitData.locationURL) {
        //     params.push(`location=${locationParam}`);
        // }
        // if (submitData.startDate) {
        //     params.push(`from=${from}`);
        // }
        // if (submitData.endDate) {
        //     params.push(`to=${to}`);
        // }
        // if (persons > 0) {
        //     params.push(`persons=${persons}`);
        // }
        // if (pdist) {
        //     params.push(`p_dist=${pdist}`);
        // }
        // if (flexible && deviation > 1) {
        //     params.push(`deviation=${deviation}`);
        // }
        // if (adults > 0) {
        //     params.push(`adults=${adults}`);
        // }
        // if (kids3 > 0) {
        //     params.push(`kids3=${kids3}`);
        // }
        // if (kids11 > 0) {
        //     params.push(`kids11=${kids11}`);
        // }
        // if (kids17 > 0) {
        //     params.push(`kids17=${kids17}`);
        // }
        // if (pets > 0) {
        //     params.push(`pets=${pets}`);
        // }

        // if (params.length > 0) {
        //     fullURL += `?${params.join('&')}`;
        // }
        // window.location.href = fullURL;
        updateSelectedFilters({
            location_id: submitData.locationURL,
            from: submitData.selecte,
            to: submitData.startDate,
            p_dist: submitData.p_dist,
            persons_adult: submitData.persons_adult,
            persons_0_3: submitData.persons_0_3,
            persons_4_11: submitData.persons_4_11,
            persons_12_17: submitData.persons_12_17,
            deviation: submitData.isFlexible,
        }, true);
    }

    onDatesChange({ startDate, endDate }) {
        const { stateDateWrapper } = this.props;
        this.setState({
            startDate: startDate && stateDateWrapper(startDate),
            endDate: endDate && stateDateWrapper(endDate),
        });
    }

    getCalendarStartingMonth() {
        const { datePublicResources, startDate } = this.state;
        return startDate ? moment(startDate) : moment().year(datePublicResources.selectedDefaults.year).month(datePublicResources.selectedDefaults.month - 1);
    }

    getNumberOfMonthsDisplayedMobile() {
        const { datePublicResources } = this.state;
        const startingMonth = moment(datePublicResources?.intervalFromDate).startOf('month');
        const endingMonth = moment(datePublicResources?.intervalToDate).endOf('month');
        return endingMonth?.diff(startingMonth, 'months') + 1;
    }

    getNumberOfMonthsToScrollThroughInitiallyMobile() {
        const { datePublicResources } = this.state;
        const startingMonth = moment(datePublicResources?.intervalFromDate).startOf('month');
        const endingMonth = this.getCalendarStartingMonth();
        return endingMonth?.diff(startingMonth, 'months');
    }

    getData() {
        const { publicResources } = this.state;
        if (!publicResources) {
            this.setState({ isLoading: true });
            const request1 = serviceData.getData(this.staticPathExtension);
            const request2 = serviceData.getData(this.datePathExtension);
            const request3 = serviceData.getData(this.guestsPathExtension, '/0'); // 0 - inMoreThanOneUnit
            const request4 = serviceData.getData(this.locationPathExtension); Promise.all([request1, request2, request3, request4])
                .then(([res1, res2, res3, res4]) => {
                    const responses = [res1, res2, res3, res4];
                    responses.forEach((currentRes) => {
                        if (currentRes.status !== 200) {
                            reportService.reportError(currentRes);
                        }
                    });

                    this.setState({
                        staticPublicResources: res1.data,
                        datePublicResources: res2.data,
                        guestsPublicResources: res3.data,
                        locationPublicResources: res4.data,
                    }, () => this.setState({ calendarStartingMonth: this.getCalendarStartingMonth() }));
                })
                .catch((err) => {
                    reportService.reportError(err);
                })
                .finally(() => {
                    this.setState({ isLoading: false });
                });
        }
    }

    getSelectedGuestsLabel() {
        const { guestsPublicResources, persons_adult, persons_0_3, persons_4_11, persons_12_17 } = this.state;
        if (!guestsPublicResources) return null;
        const totalChildrenCount = persons_0_3 + persons_4_11 + persons_12_17;
        const adultsText = persons_adult > 0 ? `${guestsPublicResources.adults.label}: ${persons_adult}` : '';
        const childrenText = totalChildrenCount > 0 ? `${guestsPublicResources.children0to3.label}: ${totalChildrenCount}` : '';
        return [adultsText, childrenText].filter((text) => text !== '').join(', ') || guestsPublicResources?.chooseLabel;
    }

    openModal = (event) => {
        event.persist();
        this.setState({ showModal: true, showLocation: true }, () => {
            this.inputLocationMobileRef.current?.focus();
        });
    }

    openGuestsModal = () => {
        this.setState({ showModal: true, showLocation: false, showDateRange: false, shouldScrollToInitialMonth: true, showGuests: true });
    }

    openDatesModal = () => {
        this.setState({ showModal: true, showLocation: false, showGuests: false, focusedInput: START_DATE, showDateRange: true });
    }

    closeModal = () => {
        const { showTypingLocationModal } = this.state;
        if (showTypingLocationModal) {
            this.setState({ showTypingLocationModal: false });
            return;
        }
        const newCalendarStartingMonth = this.getCalendarStartingMonth();
        this.setState({ showModal: false, showLocation: false, showGuests: false, showDateRange: false, shouldScrollToInitialMonth: true, calendarStartingMonth: newCalendarStartingMonth });
    }

    handleLocationInputKeyDown = (e) => {
        const { searchedArray, locationPublicResources: { recommendedLocations, withoutLocationLabel }, focusedLocationIndex, isFetchingLocations } = this.state;
        if (isFetchingLocations) return;
        const locationsCount = searchedArray.length > 0 ? searchedArray.length : recommendedLocations.length + 1;
        if (locationsCount < 1) return;
        const lastLocationIndex = locationsCount - 1;
        let nextIndex = null;
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (focusedLocationIndex === null) {
                nextIndex = 0;
                this.setState({ focusedLocationIndex: nextIndex });
            } else {
                nextIndex = focusedLocationIndex + 1;
                nextIndex = nextIndex > lastLocationIndex ? null : nextIndex;
                this.setState({ focusedLocationIndex: nextIndex });
            }
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            if (focusedLocationIndex === null) {
                nextIndex = lastLocationIndex;
                this.setState({ focusedLocationIndex: lastLocationIndex });
            } else {
                nextIndex = focusedLocationIndex - 1;
                nextIndex = nextIndex < 0 ? null : nextIndex;
                this.setState({ focusedLocationIndex: nextIndex });
            }
        } else if (e.key === 'Enter') {
            if (focusedLocationIndex === null) return;
            if (searchedArray.length > 0) {
                const location = searchedArray[focusedLocationIndex];
                this.selectLocation(location.id, location.value, location.label, focusedLocationIndex);
            } else {
                const location = recommendedLocations[focusedLocationIndex];
                const locationTitle = location?.title ?? withoutLocationLabel;
                this.selectLocation(location?.value ?? '', locationTitle, locationTitle, focusedLocationIndex);
            }
        }
        if (nextIndex !== null) {
            const locationItemId = searchedArray.length > 0 ? searchedArray[nextIndex].id : recommendedLocations[nextIndex]?.value ?? '';
            const locationElement = this.locationDropdownDesktopRef.current?.querySelector(`[data-url="${locationItemId}"]`);
            locationElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    };

    isLocationAnId = (location) => /\d/.test(location);

    pdistOnWhenMoreThan6GuestsAndUserDidntTurnOffPdist(operation) {
        if (operation !== 'minus' && operation !== 'plus') {
            throw new Error('Mora se uneti operacija pdistOnWhenMoreThan6GuestsAndUserDidntTurnOffPdist');
        }
        const stateData = this.state;
        if (stateData.userTouchedPdist) {
            return;
        }
        if (stateData.persons === 6 && stateData.p_dist === 0 && operation === 'plus') {
            this.setState({ p_dist: 1 });
        }
        if (stateData.persons === 5 && stateData.p_dist === 1 && operation === 'minus') {
            this.setState({ p_dist: 0 });
        }
    }

    showDatePicker() {
        this.setState({ showLocation: false, showGuests: false, showDateRange: true, focusedInput: START_DATE });
    }

    resetDates() {
        this.setState({ startDate: null, endDate: null, focusedInput: START_DATE });
    }

    confirmDates() {
        const stateData = this.state;
        if (stateData.focusedInput !== START_DATE) {
            this.setState({ showLocation: false, showGuests: true, showDateRange: false, shouldScrollToInitialMonth: true });
            this.guestsInput.current.focus();
        }
    }

    prepareUpdatedParamsForPassingToUpperComponentFromThisComponentSearchParamsState(thoseParamsThatAreNotFromStateButForcedInstead = {}) {
        const tmpStateData = this.state;
        const stateData = {
            ...tmpStateData,
            ...thoseParamsThatAreNotFromStateButForcedInstead,
        };

        return {
            location_id: stateData.locationURL,
            p_dist: stateData.p_dist,
            persons_adult: stateData.persons_adult,
            persons_0_3: stateData.persons_0_3,
            persons_4_11: stateData.persons_4_11,
            persons_12_17: stateData.persons_12_17,
            deviation: stateData.isFlexible,
            from: stateData.startDate ? moment(stateData.startDate).format('YYYY-MM-DD') : '',
            to: stateData.endDate ? moment(stateData.endDate).format('YYYY-MM-DD') : '',
        };
    }

    parseUrlParams(callback) {
        const fetchData = this.props;
        const searchParams = new URLSearchParams(window.location.search);
        // const localStorageSearchParameters = LocalStoreSearchParameters.getAllSearchParametersStoredInLocalStorage();
        // if (localStorageSearchParameters.from) {
        //     this.setState({ startDate: moment(localStorageSearchParameters.from, 'YYYY-MM-DD') });
        //     fetchData.update({ from: moment(localStorageSearchParameters.from).format('YYYY-MM-DD') }, false);
        // }
        // if (localStorageSearchParameters.to) {
        //     this.setState({ endDate: moment(localStorageSearchParameters.to, 'YYYY-MM-DD') });
        //     fetchData.update({ to: moment(localStorageSearchParameters.to).format('YYYY-MM-DD') }, false);
        // }
        // this.setState({
        //     searchString: localStorageSearchParameters.location,
        //     selectedLocation: localStorageSearchParameters.location,
        //     selectedLocationLabel: localStorageSearchParameters.location,
        //     locationURL: localStorageSearchParameters.location_id,
        //     p_dist: localStorageSearchParameters.p_dist,
        //     persons_adult: localStorageSearchParameters.persons_adult,
        //     persons_0_3: localStorageSearchParameters.persons_0_3,
        //     persons_4_11: localStorageSearchParameters.persons_4_11,
        //     persons_12_17: localStorageSearchParameters.persons_12_17,
        //     pets_no: localStorageSearchParameters.pets_no,
        //     isFlexible: localStorageSearchParameters.deviation,
        // }, () => fetchData.update(
        //     this.prepareUpdatedParamsForPassingToUpperComponentFromThisComponentSearchParamsState({
        //         location: localStorageSearchParameters.location,
        //     }),
        //     false,
        // ));
        const location = searchResultsWindowManipulationService.getLocationName();
        const locationID = searchResultsWindowManipulationService.getLocationID();
        if (locationID !== null) {
            this.setState({
                searchString: location,
                selectedLocation: location,
                selectedLocationLabel: location,
                locationURL: locationID,
            });
        }
        searchParams.forEach((value, key) => {
            switch (key) {
                case 'from':
                    this.setState({ startDate: moment(value, 'YYYY-MM-DD') });
                    fetchData.update({ from: moment(value).format('YYYY-MM-DD') }, false);
                    break;
                case 'to':
                    this.setState({ endDate: moment(value, 'YYYY-MM-DD') });
                    fetchData.update({ to: moment(value).format('YYYY-MM-DD') }, false);
                    break;
                case 'p_dist':
                    this.setState({ p_dist: parseInt(value, 10) });
                    fetchData.update({ p_dist: parseInt(value, 10) }, false);
                    break;
                case 'persons_adult':
                    this.setState({ persons_adult: parseInt(value, 10) });
                    fetchData.update({ persons_adult: parseInt(value, 10) }, false);
                    break;
                case 'persons_0_3':
                    this.setState({ persons_0_3: parseInt(value, 10) });
                    fetchData.update({ persons_0_3: parseInt(value, 10) }, false);
                    break;
                case 'persons_4_11':
                    this.setState({ persons_4_11: parseInt(value, 10) });
                    fetchData.update({ persons_4_11: parseInt(value, 10) }, false);
                    break;
                case 'persons_12_17':
                    this.setState({ persons_12_17: parseInt(value, 10) });
                    fetchData.update({ persons_12_17: parseInt(value, 10) }, false);
                    break;
                case 'locationId':
                    this.setState({ locationURL: decodeURIComponent(value) });
                    fetchData.update({ location_id: decodeURIComponent(value) }, false);
                    break;
                case 'location':
                    this.setState({ searchString: decodeURIComponent(value), selectedLocation: decodeURIComponent(value), selectedLocationLabel: decodeURIComponent(value) });
                    break;
                case 'deviation':
                    this.setState({ isFlexible: true });
                    fetchData.update({ deviation: true }, false);
                    break;
                case 'page':
                    if (parseInt(value, 10) > 1) { this.setState({ page: parseInt(value, 10) }); }
                    break;
                default:
                    break;
            }
        });

        this.setState(({ persons_adult, persons_0_3, persons_4_11, persons_12_17 }) => ({
            persons: persons_adult + persons_0_3 + persons_4_11 + persons_12_17,
        }), () => {
            if (callback && typeof callback === 'function') {
                callback();
            }
        });
    }

    toggleAccordion() {
        this.setState((prevState) => ({
            accordionUpdate: 1,
            isAccordionOpen: !prevState.isAccordionOpen,
        }));
    }

    toggleAccordionVisibility() {
        const propsData = this.props;
        this.setState((prevState) => ({
            isAccordionVisible: !prevState.isAccordionVisible,
        }));
        if (propsData && typeof propsData.toggleAccordion === 'function') {
            propsData.toggleAccordion();
        }
    }

    selectLocation(locationId, locationText, locationLabel, locationIndex = null) {
        const { isFetchingLocations, isSmallDevice } = this.state;
        if (this.locationBlurTimeout) {
            clearTimeout(this.locationBlurTimeout);
            this.locationBlurTimeout = null;
        }
        if (isFetchingLocations) return;
        const isFacilityOrUnit = this.isLocationAnId(locationText);
        this.fetchLocationsAbortController?.abort();
        this.setState(() => ({
            locationURL: locationId,
            searchString: locationText,
            selectedLocation: locationText,
            selectedLocationLabel: locationLabel,
            focusedLocationIndex: locationIndex,
            showModal: isSmallDevice,
            showLocation: false,
            showDateRange: !isFacilityOrUnit,
            focusedInput: isFacilityOrUnit ? null : START_DATE,
            showTypingLocationModal: false,
        }));
        if (!isFacilityOrUnit) {
            this.inputDateRangeRef.current.focus();
        }
    }

    isSearchStringAnId() {
        const { searchString } = this.state;
        return /\d/.test(searchString);
    }

    isSearchStringAllDigits() {
        const { searchString } = this.state;
        return /^\d+$/.test(searchString);
    }

    isSearchStringValid() {
        const { searchString } = this.state;
        if (!searchString) return false;
        const searchStringTrimmed = searchString.trim();
        if (searchStringTrimmed.length < 2) return false;
        const isAllDigits = this.isSearchStringAllDigits();
        if (isAllDigits && searchStringTrimmed.length < 3) return false;
        if (this.isSearchStringAnId() && !isAllDigits && searchStringTrimmed.length < 4) return false;
        return true;
    }

    isSelectedLocationInRecommendedLocations() {
        const { selectedLocation, locationPublicResources: { recommendedLocations, withoutLocationLabel } } = this.state;
        if (!recommendedLocations) { return false; }
        if (selectedLocation === withoutLocationLabel) { return true; }
        return recommendedLocations.some((location) => location.title === selectedLocation);
    }

    render() {
        const fetchData = this.props;
        const stateData = this.state;
        const { searchFormLoader } = this.props;
        const orientation = stateData.isSmallDevice ? VERTICAL_SCROLLABLE : HORIZONTAL_ORIENTATION;
        const numberOfMonths = stateData.isSmallDevice ? this.getNumberOfMonthsDisplayedMobile() : this.numberOfMonthsDisplayedDesktop;
        const renderDayInfo = (day) => <div className="selectedDateWrapper">{day.format('D')}</div>;
        const renderCalendarInfo = (
            <>
                <div className="dateRangePicker--headerOuter">
                    <div className="dateRangePicker--header">
                        <span className="dateRangePicker--title">
                            {stateData.staticPublicResources?.desktop?.datePickLongLabel}
                        </span>
                    </div>
                </div>
                {/* <div className="flexibleButtons-wrapper">
                    <button
                        type="button"
                        className={`flexibleSwitchButton ${stateData.isFlexible === false ? 'selected-option' : ''}`}
                        name="isFlexible"
                        value={stateData.isFlexible}
                        onClick={this.handleInputChange}
                    >
                        <span className="flexibleButtonLabel">{stateData.datePublicResources.specificDateLabel}</span>
                    </button>
                    <button
                        type="button"
                        className={`flexibleSwitchButton ${stateData.isFlexible === true ? 'selected-option' : ''}`}
                        name="isFlexible"
                        value={stateData.isFlexible}
                        onClick={this.handleInputChange}
                    >
                        <span className="flexibleButtonLabel">{stateData.datePublicResources.flexibleDateLabel}</span>
                    </button>
                </div> */}
                {/* <div className="header-labels">
                    <button className="cancelSearch" type="button" onClick={this.resetDates}>
                        {stateData.datePublicResources.cancelLabel}
                    </button>
                    <button className="submitSearch" type="button" onClick={this.confirmDates} disabled={!(stateData.startDate && stateData.endDate)}>
                        {stateData.datePublicResources.confirmLabel}
                    </button>
                </div> */}
                {
                    /* OVO OSTAVLJAMO ZA PRVI UPDATE
                    {stateData.isFlexible && (
                        <div className="flexibleInput">
                            <>
                                <div className="flexbileInput-left">
                                    <p>{stateData.datePublicResources.lengthOfStayLabel}</p>
                                    <span>{stateData.datePublicResources.lengthOfStayDescription}</span>
                                </div>
                                <div className="flexbileInput-right">
                                    <button
                                        type="button"
                                        data-operation="-"
                                        onClick={this.handleFlexible}
                                        disabled={stateData.startDate === null || stateData.endDate === null}
                                    >
                                        -
                                    </button>
                                    <p>{stateData.flexibleDays}</p>
                                    <button
                                        type="button"
                                        data-operation="+"
                                        onClick={this.handleFlexible}
                                        disabled={stateData.startDate === null || stateData.endDate === null}
                                    >
                                        +
                                    </button>
                                </div>
                            </>
                        </div>
                    )} */
                }
            </>
        );
        const guestsBlock = (device) => (
            <>
                {stateData.guestsPublicResources && (
                    <>
                        <div className="guestsAdults--wrapper">
                            <div className="guestsAdults">
                                <div className="guestsLeft">
                                    <p>{stateData.guestsPublicResources.adults.label}</p>
                                    <span>{stateData.guestsPublicResources.adults.description}</span>
                                </div>
                                <div className="guestsRight">
                                    <button
                                        data-operation="minus"
                                        value={stateData.persons_adult}
                                        name="persons_adult"
                                        type="button"
                                        className="leftButton"
                                        data-prop="adults"
                                        onClick={this.handleGuests}
                                        disabled={
                                            (stateData.persons_adult === stateData.guestsPublicResources.adults.minimumNumber || stateData.persons_0_3 > 0
                                            || stateData.persons_4_11 > 0 || stateData.persons_12_17 > 0) && stateData.persons_adult <= 1
                                        }
                                    >
                                        -
                                    </button>
                                    <p>{stateData.persons_adult}</p>
                                    <button
                                        data-operation="+"
                                        value={stateData.persons_adult}
                                        name="persons_adult"
                                        type="button"
                                        className="rightButton"
                                        data-prop="adults"
                                        onClick={this.handleGuests}
                                        disabled={stateData.persons_adult >= stateData.guestsPublicResources.adults.maximumNumber}
                                    >
                                        +
                                    </button>
                                </div>
                                <hr className="guestsSeparator" />
                            </div>
                            <div className="guestsAdults">
                                <div className="guestsLeft">
                                    <p>{stateData.guestsPublicResources.children0to3.label}</p>
                                    <span>{stateData.guestsPublicResources.children0to3.description}</span>
                                </div>
                                <div className="guestsRight">
                                    <button
                                        data-operation="minus"
                                        value={stateData.persons_0_3}
                                        name="persons_0_3"
                                        type="button"
                                        className="leftButton"
                                        data-prop="children0to3"
                                        onClick={this.handleGuests}
                                        disabled={stateData.persons_0_3 === 0}
                                    >
                                        -
                                    </button>
                                    <p>{stateData.persons_0_3}</p>
                                    <button
                                        data-operation="+"
                                        value={stateData.persons_0_3}
                                        name="persons_0_3"
                                        type="button"
                                        className="rightButton"
                                        data-prop="children0to3"
                                        onClick={this.handleGuests}
                                        disabled={stateData.persons_0_3 >= stateData.guestsPublicResources.children0to3.maximumNumber}
                                    >
                                        +
                                    </button>
                                </div>
                                <hr className="guestsSeparator" />
                            </div>
                            <div className="guestsAdults">
                                <div className="guestsLeft">
                                    <p>{stateData.guestsPublicResources.children4to11.label}</p>
                                    <span>{stateData.guestsPublicResources.children4to11.description}</span>
                                </div>
                                <div className="guestsRight">
                                    <button
                                        data-operation="minus"
                                        value={stateData.persons_4_11}
                                        name="persons_4_11"
                                        type="button"
                                        className="leftButton"
                                        data-prop="children4to11"
                                        onClick={this.handleGuests}
                                        disabled={stateData.persons_4_11 === 0}
                                    >
                                        -
                                    </button>
                                    <p>{stateData.persons_4_11}</p>
                                    <button
                                        data-operation="+"
                                        value={stateData.persons_4_11}
                                        name="persons_4_11"
                                        type="button"
                                        className="rightButton"
                                        data-prop="children4to11"
                                        onClick={this.handleGuests}
                                        disabled={stateData.persons_4_11 >= stateData.guestsPublicResources.children4to11.maximumNumber}
                                    >
                                        +
                                    </button>
                                </div>
                                <hr className="guestsSeparator" />
                            </div>
                            <div className="guestsAdults">
                                <div className="guestsLeft">
                                    <p>{stateData.guestsPublicResources.children12to17.label}</p>
                                    <span>{stateData.guestsPublicResources.children12to17.description}</span>
                                </div>
                                <div className="guestsRight">
                                    <button
                                        data-operation="minus"
                                        value={stateData.persons_12_17}
                                        name="persons_12_17"
                                        type="button"
                                        className="leftButton"
                                        data-prop="children12to17"
                                        onClick={this.handleGuests}
                                        disabled={stateData.persons_12_17 === 0}
                                    >
                                        -
                                    </button>
                                    <p>{stateData.persons_12_17}</p>
                                    <button
                                        data-operation="+"
                                        value={stateData.persons_12_17}
                                        name="persons_12_17"
                                        type="button"
                                        className="rightButton"
                                        data-prop="children12to17"
                                        onClick={this.handleGuests}
                                        disabled={stateData.persons_12_17 >= stateData.guestsPublicResources.children12to17.maximumNumber}
                                    >
                                        +
                                    </button>
                                </div>
                                <hr className="guestsSeparator" />
                            </div>
                            <div className="guestsAdults">
                                <div className="guestsLeft">
                                    <p>{stateData.guestsPublicResources.inSeveralAccommodation.label}</p>
                                </div>
                                <div className="guestsRight guestsRight--multipleUnits">
                                    <div className="custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={`customSwitch2-${device}`}
                                            name="p_dist"
                                            value={stateData.p_dist}
                                            checked={stateData.p_dist}
                                            onChange={this.handlePdistChange}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor={`customSwitch2-${device}`}
                                            aria-label={stateData.guestsPublicResources.inSeveralAccommodation.label}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
        const getLocationsDropdownTitle = () => (this.isSearchStringAnId() ? stateData.locationPublicResources.locationsLabelResultFacilityOrUnitString : stateData.locationPublicResources.locationsLabelResultLocationString);
        const locationBlock = (
            <>
                {stateData.isFetchingLocations && (
                    <>
                        <h3 className="locationDropdown__recommendedLabel">{getLocationsDropdownTitle()}</h3>
                        <div className="locations-wrap">
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </div>
                    </>
                )}
                {!stateData.isFetchingLocations && stateData.searchString && stateData.searchedArray.length > 0 ? (
                    <>
                        <h3 className="locationDropdown__recommendedLabel">{getLocationsDropdownTitle()}</h3>
                        <div className="locations-wrap">
                            {stateData.searchedArray.map((item, i) => (
                                <button
                                    key={i}
                                    onClick={() => this.selectLocation(item.id, item.value, item.label, i)}
                                    type="button"
                                    data-value={item.value}
                                    data-url={item.id}
                                    aria-label="Select Location"
                                    className={`location-dropdown__item ${stateData.locationURL === item.id ? 'location-dropdown__item--selected' : ''} ${stateData.focusedLocationIndex === i ? 'location-dropdown__item--focused' : ''}`}
                                >
                                    {this.isSearchStringAnId()
                                        ? <MdApartment className="location-dropdown__item__icon" />
                                        : <MdOutlinePlace className="location-dropdown__item__icon" />}
                                    <span className="location-dropdown__item__text" dangerouslySetInnerHTML={{ __html: `${item.label}` }} />
                                </button>
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        {!stateData.isFetchingLocations && (
                            <>
                                {this.isSearchStringValid() ? (
                                    !this.isSelectedLocationInRecommendedLocations() && (
                                        <div className="location-dropdown__info-box">
                                            <MdInfoOutline className="location-dropdown__info-box__icon" />
                                            <p className="location-dropdown__info-box__text">{stateData.locationPublicResources.unrecognizedLocationLabel}</p>
                                        </div>
                                    )
                                ) : (
                                    <div className="location-dropdown__info-box">
                                        <MdInfoOutline className="location-dropdown__info-box__icon" />
                                        <p className="location-dropdown__info-box__text">{stateData.locationPublicResources.inputUsageInstructions}</p>
                                    </div>
                                )}
                                <h3 className="locationDropdown__recommendedLabel">{stateData.locationPublicResources.recommendedLabel}</h3>
                                {stateData.locationPublicResources && (
                                    <div className="locations-wrap">
                                        {stateData.locationPublicResources.recommendedLocations.map((item, i) => (
                                            <button
                                                key={i}
                                                onClick={() => this.selectLocation(item.value, item.title, item.title, i)}
                                                type="button"
                                                data-value={item.title}
                                                data-url={item.value}
                                                className={`location-dropdown__item ${stateData.locationURL === item.value ? 'location-dropdown__item--selected' : ''} ${stateData.focusedLocationIndex === i ? 'location-dropdown__item--focused' : ''}`}
                                            >
                                                <MdOutlinePlace className="location-dropdown__item__icon" />
                                                {item.title}
                                            </button>
                                        ))}
                                        <button
                                            className={`location-dropdown__item location-dropdown__item--entire-croatia ${stateData.selectedLocation === stateData.locationPublicResources.withoutLocationLabel ? 'location-dropdown__item--selected' : ''} ${stateData.focusedLocationIndex === stateData.locationPublicResources.recommendedLocations.length ? 'location-dropdown__item--focused' : ''}`}
                                            type="button"
                                            data-value={stateData.locationPublicResources.withoutLocationLabel}
                                            data-url=""
                                            onClick={() => this.selectLocation('', stateData.locationPublicResources.withoutLocationLabel, stateData.locationPublicResources.withoutLocationLabel, stateData.locationPublicResources.recommendedLocations.length)}
                                        >
                                            <MdOutlineMyLocation className="location-dropdown__item__icon" />
                                            {stateData.locationPublicResources.withoutLocationLabel}
                                        </button>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </>
        );
        const minDate = moment(stateData.datePublicResources?.intervalFromDate);
        const maxDate = moment(stateData.datePublicResources?.intervalToDate);

        return (
            <>
                {stateData.isLoading && (
                    <>
                        {searchFormLoader}
                    </>
                )}
                {stateData.staticPublicResources && (
                    <div className={`searchFormWrap ${stateData.showModal ? 'zLow' : ''}`} id="searchFormWrap">
                        {stateData.showModal && (
                            <ModalControl
                                modalControlIsOpen={stateData.showModal}
                                modalControlDisableBodyScroll={true}
                                modalControlOnClose={this.closeModal}
                                modalControlDependentState={stateData.isAccordionVisible}
                                modalControlDependentHandler={this.handleSearchFormBackButton}
                            >
                                <div className="mobileSearchModal" translate="no">
                                    <div className={`content ${stateData.showTypingLocationModal ? 'content--typingLocationModal' : ''}`}>
                                        <div className="header">
                                            <button type="button" className="closeQuickSearchModal" onClick={this.closeModal} aria-label="Close">
                                                {stateData.showTypingLocationModal ? <MdNavigateBefore /> : <MdClose />}
                                            </button>
                                            <h2>{stateData.staticPublicResources.mobile.formTitle}</h2>
                                        </div>
                                        <div className="content-inner">
                                            {stateData.showTypingLocationModal && (
                                                <h3 className="typingLocationModalTitle">
                                                    {stateData.staticPublicResources.mobile.locationLabel}
                                                </h3>
                                            )}
                                            <div className={`locationWrap modalBorder ${stateData.showLocation && 'active'} ${!stateData.showLocation && stateData.searchString && !stateData.selectedLocation ? 'locationWrap--error' : ''}`}>
                                                {!stateData.showTypingLocationModal && (
                                                    <button
                                                        type="button"
                                                        className={`location mainButton ${stateData.showLocation && 'active'}`}
                                                        name="showLocation"
                                                        onClick={this.handleModal}
                                                    >
                                                        {!stateData.showLocation && <MdOutlinePlace />}
                                                        {stateData.selectedLocation ?? (!stateData.showLocation && stateData.searchString ? stateData.searchString : stateData.staticPublicResources.mobile.locationLabel)}
                                                    </button>
                                                )}
                                                {stateData.searchString && (
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            this.setState({
                                                                searchString: '',
                                                                selectedLocation: null,
                                                                selectedLocationLabel: null,
                                                                locationURL: '',
                                                                showDateRange: false,
                                                                shouldScrollToInitialMonth: true,
                                                                showGuests: false,
                                                                showLocation: true,
                                                                searchedArray: [],
                                                            });
                                                        }}
                                                        aria-label="Clear Locations"
                                                        className={`DateRangePickerInput_clearDates ${stateData.showTypingLocationModal ? 'DateRangePickerInput_clearDates--typingLocationModal' : ''}`}
                                                    >
                                                        <IoMdCloseCircleOutline />
                                                    </button>
                                                )}
                                                {stateData.showLocation && (
                                                    <div className="locationModal fillModal">
                                                        <div
                                                            data-modal="showLocation"
                                                            className="locationDropdown"
                                                        >
                                                            <div className="inputLocationOuter">
                                                                <MdOutlinePlace className="input-location__icon" />
                                                                <input
                                                                    autoComplete="off"
                                                                    className={`inputLocation ${stateData.searchString && !stateData.selectedLocation && !stateData.showTypingLocationModal ? 'inputLocation--error' : ''}`}
                                                                    name="location"
                                                                    type="text"
                                                                    placeholder={stateData.staticPublicResources.desktop.locationLabel}
                                                                    value={stateData.searchString || ''}
                                                                    onChange={this.handleLocationInputChange}
                                                                    ref={this.inputLocationMobileRef}
                                                                    onFocus={this.handleFocusInputLocation}
                                                                    onKeyDown={this.handleLocationInputKeyDown}
                                                                />
                                                                {stateData.searchString && !stateData.selectedLocation && !stateData.showTypingLocationModal && (
                                                                    <p className="location-error-message">
                                                                        {stateData.locationPublicResources.incorrectLocationLabelErrorString}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            {stateData.showTypingLocationModal && (
                                                                <div className="locationsContainer">
                                                                    {locationBlock}
                                                                </div>
                                                            )}
                                                            {!stateData.showTypingLocationModal && (
                                                                <div className="recommendedLocations">
                                                                    <span className="recommendedLocations--title">{stateData.locationPublicResources.recommendedLabel}</span>
                                                                    <div className="recommendedLocations--content">
                                                                        {stateData.locationPublicResources.recommendedLocations.map((item, index) => (
                                                                            <button
                                                                                key={index}
                                                                                onClick={() => this.selectLocation(item.value, item.title, item.title, index)}
                                                                                type="button"
                                                                                data-value={item.title}
                                                                                data-url={item.value}
                                                                                className="recommendedLocations--item"
                                                                            >
                                                                                {item.title}
                                                                            </button>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {!stateData.showTypingLocationModal && (
                                                <div ref={this.dateRangeWrapRef} className={`dateRangeWrap modalBorder ${stateData.showDateRange && 'active'}`}>
                                                    <button
                                                        type="button"
                                                        className={`dateRange mainButton ${stateData.showDateRange && 'active'}`}
                                                        name="showDateRange"
                                                        onClick={this.handleModal}
                                                    >
                                                        {!stateData.showDateRange && <span><MdOutlineCalendarToday /></span>}
                                                        {stateData.endDate
                                                            ? `${moment(stateData.startDate).format('DD.MM.YYYY')} - ${moment(stateData.endDate).format('DD.MM.YYYY')}`
                                                            : stateData.staticPublicResources.mobile.datePickLabel}
                                                    </button>
                                                    {stateData.endDate && (
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                this.setState({
                                                                    showDateRange: true,
                                                                    showGuests: false,
                                                                    showLocation: false,
                                                                    startDate: null,
                                                                    endDate: null,
                                                                    focusedInput: START_DATE,
                                                                }, () => fetchData.update(
                                                                    this.prepareUpdatedParamsForPassingToUpperComponentFromThisComponentSearchParamsState(),
                                                                    false,
                                                                ));
                                                            }}
                                                            aria-label="Clear Date Range"
                                                            data-modal="showDateRange"
                                                            className="DateRangePickerInput_clearDates"
                                                        >
                                                            <IoMdCloseCircleOutline />
                                                        </button>
                                                    )}
                                                    {stateData.showDateRange && (
                                                        <div className="dateRangeModal fillModal">
                                                            {renderCalendarInfo}
                                                            {stateData.isSmallDevice && (
                                                                <>
                                                                    <DateRangePicker
                                                                        ref={this.handleDateRangePickerMobileRef}
                                                                        displayFormat={() => 'DD.MM.YYYY'}
                                                                        startDate={stateData.startDate}
                                                                        startDateId="your_unique_start_date_idd"
                                                                        endDate={stateData.endDate}
                                                                        endDateId="your_unique_end_date_idd"
                                                                        daySize={45}
                                                                        onDatesChange={({ startDate, endDate }) => {
                                                                            this.setState({ startDate, endDate });
                                                                            if (endDate) {
                                                                                const dayDiff = Math.ceil(endDate.diff(startDate) / (1000 * 60 * 60 * 24));
                                                                                if (dayDiff > 7) {
                                                                                    this.setState({ flexibleDays: 7 });
                                                                                } else {
                                                                                    this.setState({ flexibleDays: 1 });
                                                                                }
                                                                                this.setState({
                                                                                    focusedInput: START_DATE,
                                                                                });
                                                                                this.confirmDates();
                                                                            }
                                                                            if (startDate === null && endDate === null) {
                                                                                this.setState({ flexibleDays: 1 });
                                                                            }
                                                                            if (stateData.focusedInput === START_DATE) {
                                                                                this.setState({ endDate: null });
                                                                                this.confirmDates();
                                                                            }
                                                                        }}
                                                                        focusedInput={stateData.focusedInput}
                                                                        onFocusChange={(focusedInput) => {
                                                                            if (focusedInput) {
                                                                                this.setState({ focusedInput });
                                                                            }
                                                                        }}
                                                                        onClose={({ startDate }) => {
                                                                            if (startDate) {
                                                                                this.setState({ calendarStartingMonth: this.getCalendarStartingMonth(), shouldScrollToInitialMonth: true });
                                                                            }
                                                                        }}
                                                                        initialVisibleMonth={() => minDate}
                                                                        orientation={orientation}
                                                                        numberOfMonths={numberOfMonths}
                                                                        keepOpenOnDateSelect={true}
                                                                        keepFocusOnInput={true}
                                                                        showClearDates={true}
                                                                        verticalHeight={300}
                                                                        readOnly={true}
                                                                        noBorder={true}
                                                                        renderDayContents={(day) => renderDayInfo(day)}
                                                                        isDayHighlighted={(day) => day.day() % 6 === 0}
                                                                        isOutsideRange={(day) => day.isBefore(minDate, 'day') || day.isAfter(maxDate, 'day')}
                                                                        minDate={minDate}
                                                                        maxDate={maxDate}
                                                                        customCloseIcon={<IoMdCloseCircleOutline />}
                                                                    />
                                                                    {/* <div className="date-footer modal-footer">
                                                                        <button
                                                                            className="cancelSearch"
                                                                            type="button"
                                                                            onClick={this.resetDates}
                                                                        >
                                                                            {stateData.datePublicResources.cancelLabel}
                                                                        </button>
                                                                        <button
                                                                            className="submitSearch"
                                                                            type="button"
                                                                            onClick={this.confirmDates}
                                                                        >
                                                                            {stateData.datePublicResources.confirmLabel}
                                                                        </button>
                                                                    </div> */}
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {!stateData.showTypingLocationModal && (
                                                <div className={`guestsWrap modalBorder ${stateData.showGuests && 'active'}`}>
                                                    <button
                                                        type="button"
                                                        className={`guests mainButton ${stateData.showGuests && 'active'}`}
                                                        name="showGuests"
                                                        onClick={this.handleModal}
                                                    >
                                                        {!stateData.showGuests && <MdPersonOutline />}
                                                        {stateData.persons > stateData.guestsPublicResources.adults.minimumNumber
                                                            ? ` ${stateData.guestsPublicResources.selectedNumberOfGuestsLabel} ${stateData.persons}`
                                                            : stateData.staticPublicResources.mobile.numberOfGuestsLabel}
                                                    </button>
                                                    {stateData.persons > stateData.guestsPublicResources.adults.minimumNumber && (
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                this.setState({
                                                                    persons: stateData.guestsPublicResources.adults.minimumNumber,
                                                                    persons_adult: stateData.guestsPublicResources.adults.minimumNumber,
                                                                    persons_0_3: 0,
                                                                    persons_4_11: 0,
                                                                    persons_12_17: 0,
                                                                    p_dist: 0,
                                                                    showDateRange: false,
                                                                    shouldScrollToInitialMonth: true,
                                                                    showLocation: false,
                                                                    showGuests: true,
                                                                });
                                                            }}
                                                            aria-label="Clear Guests"
                                                            data-modal="showGuests"
                                                            className="DateRangePickerInput_clearDates"
                                                        >
                                                            <IoMdCloseCircleOutline />
                                                        </button>
                                                    )}
                                                    {stateData.showGuests && (
                                                        <div className="guestsModal fillModal">
                                                            <div
                                                                data-modal="showGuests"
                                                                className="guestsDropdown"
                                                            >
                                                                {guestsBlock('desktop')}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="modal-footer">
                                            <button className="cancelSearch" type="button" onClick={this.closeModal}>
                                                {stateData.datePublicResources.cancelLabel}
                                            </button>
                                            <button
                                                className="submitSearch"
                                                type="button"
                                                disabled={stateData.searchString && !stateData.selectedLocation}
                                                onClick={() => {
                                                    fetchData.update(
                                                        this.prepareUpdatedParamsForPassingToUpperComponentFromThisComponentSearchParamsState(),
                                                        true,
                                                    );
                                                    if (typeof zaraz !== 'undefined' && zaraz.consent) {
                                                        const marketing = zaraz.consent.get('KlIi');
                                                        if (zaraz.track && marketing) {
                                                            if (fetchData.isHome) {
                                                                zaraz.track('HomepageSearchQuickLinkClickDetailed');
                                                            } else if (fetchData.isPillarPage) {
                                                                zaraz.track('PillarPageSearchQuickLinkClickDetailed');
                                                            } else {
                                                                zaraz.track('SearchResultsQuickSearchSubmitDetailed');
                                                            }
                                                        }
                                                    }
                                                }}
                                            >
                                                {stateData.staticPublicResources.mobile.searchLabel}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ModalControl>
                        )}
                        <form
                            className="mainSearchForm"
                            translate="no"
                            onTouchStart={(event) => {
                                if (fetchData.isHome || fetchData.isPillarPage) return;
                                document.body.style.overflow = 'hidden';
                                this.touchStartY = event.touches[0].clientY;
                                event.preventDefault();
                            }}
                            onTouchMove={(event) => {
                                if (fetchData.isHome || fetchData.isPillarPage) return;
                                document.body.style.overflow = 'hidden';
                                event.preventDefault();
                            }}
                            onTouchEnd={(event) => {
                                if (fetchData.isHome || fetchData.isPillarPage) return;
                                const touchEndY = event.changedTouches[0].clientY;
                                const touchDiff = touchEndY - this.touchStartY;
                                if (touchDiff < -20 || touchDiff > 20) {
                                    this.toggleAccordionVisibility();
                                }
                                document.body.style.overflow = 'auto';
                            }}
                        >
                            <div className={`mainSearchForm--caret ${stateData.showLocation ? 'caret--locationDropdown' : ''} ${stateData.showDateRange ? 'caret--dateRangePickerDropdown' : ''} ${stateData.showGuests ? 'caret--guestsDropdown' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="21" viewBox="0 0 26 21" fill="none">
                                    <path d="M13 0L25.1244 21H0.875645L13 0Z" fill="white" />
                                </svg>
                            </div>
                            {fetchData.isDataAttributeTrue ? (
                                <div className="whatsapp d-md-none d-block">
                                    <a href="https://wa.me/+385959052828" aria-label="Whatsapp" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                            <g clipPath="url(#clip0_11687_94079)">
                                                <path d="M0 48L3.31031 36C1.15866 32.2759 0 28.1379 0 23.8344C0 10.7587 10.7587 0 23.9172 0C37.0758 0 48 10.7587 48 23.8344C48 36.9102 37.0759 48 23.9172 48C19.7793 48 15.8069 46.6759 12.2482 44.6897L0 48Z" fill="#EDEDED" />
                                                <path d="M12.9101 40.3859L13.6549 40.7998C16.717 42.7032 20.2756 43.8618 23.9998 43.8618C34.8411 43.8618 43.8618 34.8411 43.8618 23.8342C43.8618 12.8273 34.8411 4.1377 23.917 4.1377C12.9929 4.1377 4.1377 12.9101 4.1377 23.8342C4.1377 27.6411 5.21357 31.3652 7.19976 34.5101L7.69635 35.2549L5.79295 42.2067L12.9101 40.3859Z" fill="#55CD6C" />
                                                <path d="M17.5447 12.7449L15.9723 12.6621C15.4757 12.6621 14.9792 12.8277 14.6482 13.1587C13.9034 13.8208 12.662 15.0621 12.331 16.7174C11.7517 19.2001 12.662 22.1794 14.8138 25.1587C16.9655 28.138 21.1035 32.938 28.3861 35.007C30.7034 35.669 32.5241 35.2552 34.0138 34.3449C35.1724 33.6 35.9172 32.4415 36.1655 31.2L36.4138 30.0414C36.4965 29.7104 36.331 29.2965 36 29.1311L30.7861 26.7311C30.4551 26.5655 30.0413 26.6483 29.793 26.9793L27.7241 29.6276C27.5585 29.7931 27.3103 29.8758 27.062 29.7931C25.6551 29.2965 20.9379 27.3104 18.3723 22.3449C18.2895 22.0966 18.2895 21.8483 18.4551 21.6828L20.4413 19.4484C20.6069 19.2001 20.6895 18.8691 20.6069 18.6208L18.2069 13.2415C18.124 12.9931 17.8757 12.7449 17.5447 12.7449Z" fill="#FEFEFE" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_11687_94079">
                                                    <rect width="48" height="48" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                </div>
                            ) : null}
                            <div
                                className={`mainSearchForm--horizontalRule ${stateData.isAccordionVisible ? '' : 'collapsed'}`}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' || event.key === ' ') {
                                        this.toggleAccordionVisibility();
                                    }
                                }}
                                role="button"
                                tabIndex={0}
                                onClick={this.toggleAccordionVisibility}
                                aria-label="Close"
                            >
                                <hr />
                            </div>
                            <div
                                className={`mainSearchFormAccordionButton d-lg-none ${stateData.isAccordionOpen ? '' : 'collapsed'}`}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' || event.key === ' ') {
                                        this.toggleAccordion();
                                    }
                                }}
                                role="button"
                                tabIndex={0}
                                onClick={this.toggleAccordion}
                            >
                                <div className="mainSearchFormAccordionButton--left">
                                    <p className="mainSearchFormAccordionButton--leftLocation">{stateData.searchString ? stateData.searchString : stateData.staticPublicResources.desktop.locationLabel}</p>
                                    <p className="mainSearchFormAccordionButton--leftDateGuests">
                                        {stateData.endDate
                                            ? ` ${moment(stateData.startDate).format('DD.MM')} - ${moment(stateData.endDate).format('DD.MM')}`
                                            : stateData.staticPublicResources.desktop.datePickLabel}{' '} |{' '}
                                        {stateData.persons > 0
                                            ? ` ${stateData.guestsPublicResources.selectedNumberOfGuestsLabel} ${stateData.persons}`
                                            : stateData.staticPublicResources.desktop.numberOfGuestsLabel}
                                    </p>
                                </div>
                                <div className="mainSearchFormAccordionButton--right">
                                    <RiPencilLine />
                                </div>
                            </div>
                            <div className={`mainSearchFormAccordion--items d-lg-none ${!stateData.isAccordionOpen ? '' : 'collapsed'}  ${stateData.isAccordionVisible ? '' : 'closeSearchDisplay'}`}>
                                {fetchData.shouldRenderHeaderTitle && (
                                    <div className="mainSearchFormAccordion--header">
                                        <p className="mainSearchFormAccordion--headerTitle">{stateData.staticPublicResources.common.SEOTitle}</p>
                                    </div>
                                )}
                                <div
                                    className="mainSearchFormAccordion--item"
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter' || event.key === ' ') {
                                            this.openModal();
                                        }
                                    }}
                                    role="button"
                                    tabIndex={0}
                                    onClick={this.openModal}
                                >
                                    <div className="mainSearchFormAccordion--itemLeft">
                                        <MdOutlinePlace className="mainSearchFormAccordion__item__icon" />
                                        {(fetchData.isHome || fetchData.isPillarPage) && (
                                            stateData.selectedLocation ? (
                                                <span className="mainSearchFormAccordion__item__label" title={stateData.selectedLocation} dangerouslySetInnerHTML={{ __html: stateData.selectedLocationLabel }} />
                                            ) : (
                                                <span className="mainSearchFormAccordion__item__label">
                                                    {stateData.searchString ? stateData.searchString : (
                                                        <>
                                                            <strong>{stateData.staticPublicResources.desktop.locationLabel}</strong><br /><span title={stateData.locationPublicResources.inputUsageInstructions}>{stateData.locationPublicResources.inputUsageInstructions}</span>
                                                        </>
                                                    )}
                                                </span>
                                            )
                                        )}
                                        {!fetchData.isHome && !fetchData.isPillarPage && (
                                            stateData.searchString ? stateData.searchString : stateData.staticPublicResources.desktop.locationLabel
                                        )}
                                    </div>
                                    {!fetchData.isHome && !fetchData.isPillarPage && (
                                        <div className="mainSearchFormAccordion--itemRight">
                                            <RiPencilLine />
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="mainSearchFormAccordion--item"
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter' || event.key === ' ') {
                                            this.openDatesModal();
                                        }
                                    }}
                                    role="button"
                                    tabIndex={0}
                                    onClick={this.openDatesModal}
                                >
                                    <div className="mainSearchFormAccordion--itemLeft">
                                        <MdOutlineCalendarToday className="mainSearchFormAccordion__item__icon" />
                                        {(fetchData.isHome || fetchData.isPillarPage) && (
                                            <div className="mainSearchFormAccordion__item__label">
                                                <strong>{stateData.staticPublicResources.desktop.datePickLabel}</strong>
                                                <br />
                                                {stateData.endDate
                                                    ? `${moment(stateData.startDate).format('DD.MM')} - ${moment(stateData.endDate).format('DD.MM')}`
                                                    : stateData.staticPublicResources.desktop.datePickLongLabel}
                                            </div>
                                        )}
                                        {!fetchData.isHome && !fetchData.isPillarPage && (
                                            stateData.endDate
                                                ? ` ${moment(stateData.startDate).format('DD.MM')} - ${moment(stateData.endDate).format('DD.MM')}`
                                                : stateData.staticPublicResources.desktop.datePickLabel
                                        )}
                                    </div>
                                    {!fetchData.isHome && !fetchData.isPillarPage && (
                                        <div className="mainSearchFormAccordion--itemRight">
                                            <RiPencilLine />
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="mainSearchFormAccordion--item"
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter' || event.key === ' ') {
                                            this.openGuestsModal();
                                        }
                                    }}
                                    role="button"
                                    tabIndex={0}
                                    onClick={this.openGuestsModal}
                                >
                                    <div className="mainSearchFormAccordion--itemLeft">
                                        <MdPersonOutline className="mainSearchFormAccordion__item__icon" />
                                        {(fetchData.isHome || fetchData.isPillarPage) && (
                                            <div className="mainSearchFormAccordion__item__label">
                                                <strong>{stateData.staticPublicResources.desktop.numberOfGuestsLabel}</strong>
                                                <br />
                                                {this.getSelectedGuestsLabel()}
                                            </div>
                                        )}
                                        {!fetchData.isHome && !fetchData.isPillarPage && (
                                            stateData.persons > stateData.guestsPublicResources.adults.minimumNumber
                                                ? ` ${stateData.guestsPublicResources.selectedNumberOfGuestsLabel} ${stateData.persons}`
                                                : stateData.staticPublicResources.desktop.numberOfGuestsLabel
                                        )}
                                    </div>
                                    {!fetchData.isHome && !fetchData.isPillarPage && (
                                        <div className="mainSearchFormAccordion--itemRight">
                                            <RiPencilLine />
                                        </div>
                                    )}
                                </div>
                                {(fetchData.isHome || fetchData.isPillarPage) ? (
                                    <button
                                        type="button"
                                        aria-label="Submit Search"
                                        className="form__button form__button--primary"
                                        disabled={stateData.searchString && !stateData.selectedLocation}
                                        onClick={() => {
                                            fetchData.update(
                                                this.prepareUpdatedParamsForPassingToUpperComponentFromThisComponentSearchParamsState(),
                                                true,
                                            );
                                            if (typeof zaraz !== 'undefined' && zaraz.consent) {
                                                const marketing = zaraz.consent.get('KlIi');
                                                if (zaraz.track && marketing) {
                                                    zaraz.track('searchAction', {
                                                        location_id: stateData.locationURL,
                                                        from: stateData.startDate ? moment(stateData.startDate).format('YYYY-MM-DD') : '',
                                                        to: stateData.endDate ? moment(stateData.endDate).format('YYYY-MM-DD') : '',
                                                        p_dist: stateData.p_dist,
                                                        persons_adult: stateData.persons_adult,
                                                        persons_0_3: stateData.persons_0_3,
                                                        persons_4_11: stateData.persons_4_11,
                                                        persons_12_17: stateData.persons_12_17,
                                                        deviation: stateData.isFlexible,
                                                    });
                                                    if (fetchData.isHome) {
                                                        zaraz.track('HomepageQuickSearchSubmit');
                                                    } else {
                                                        zaraz.track('PillarPageQuickSearchSubmit');
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        <MdOutlineSearch className="mainSearchFormAccordion__search-button__icon" />
                                        <span className="mainSearchFormAccordion__search-button__text">{stateData.staticPublicResources.mobile.searchLabel}</span>
                                    </button>
                                ) : (
                                    <button type="button" className="mainSearchFormAccordion--close" onClick={this.toggleAccordion} aria-label="Close">
                                        {stateData.staticPublicResources.mobile.closeLabel}
                                    </button>
                                )}
                                {fetchData && (
                                    <button
                                        className="submitSearch"
                                        type="button"
                                        aria-label="Submit Search"
                                        onClick={() => {
                                            fetchData.update(
                                                this.prepareUpdatedParamsForPassingToUpperComponentFromThisComponentSearchParamsState(),
                                                true,
                                            );
                                            if (typeof zaraz !== 'undefined' && zaraz.consent) {
                                                const marketing = zaraz.consent.get('KlIi');
                                                if (zaraz.track && marketing) {
                                                    zaraz.track('MobileDockQuickSearchSubmit');
                                                }
                                            }
                                        }}
                                    >
                                        {stateData.staticPublicResources.mobile.searchLabel}
                                    </button>
                                )}
                            </div>
                            <div
                                className={`inputLocationOuter d-lg-block d-none ${stateData.showLocation ? 'inputLocationOuter--shown' : ''} ${stateData.selectedLocation ? 'inputLocationOuter--selected' : ''} ${stateData.searchString && !stateData.selectedLocation ? 'inputLocationOuter--error' : ''}`}
                                onBlur={this.handleBlurLocationsDesktop}
                                onFocus={this.handleFocus}
                                data-modal="showLocation"
                                tabIndex={-1}
                            >
                                <div
                                    role="button"
                                    className="input-location-inner"
                                    onFocus={(e) => {
                                        this.locationInput.current.focus();
                                        this.handleFocus(e);
                                    }}
                                    data-modal="showLocation"
                                    tabIndex={0}
                                >
                                    <MdOutlinePlace className="input-location__icon" />
                                    <div className="input-location__wrapper">
                                        <input
                                            ref={this.locationInput}
                                            autoComplete="off"
                                            className={`inputLocation ${stateData.showLocation ? 'inputLocation--shown' : ''} ${stateData.selectedLocation ? 'inputLocation--selected' : ''} ${stateData.searchString && !stateData.selectedLocation ? 'inputLocation--error' : ''}`}
                                            name="location"
                                            type="text"
                                            placeholder={stateData.staticPublicResources.desktop.locationLabel}
                                            value={stateData.searchString || ''}
                                            onChange={this.handleLocationInputChange}
                                            onKeyDown={this.handleLocationInputKeyDown}
                                        />
                                        {(fetchData.isHome || fetchData.isPillarPage) && (
                                            stateData.selectedLocation ? (
                                                <span className="input-location__wrapper__label" title={stateData.selectedLocation} dangerouslySetInnerHTML={{ __html: stateData.selectedLocationLabel }} />
                                            ) : (
                                                <span className="input-location__wrapper__label">
                                                    {stateData.searchString ? stateData.searchString : (
                                                        <>
                                                            <strong>{stateData.staticPublicResources.desktop.locationLabel}</strong><br /><span title={stateData.locationPublicResources.inputUsageInstructions}>{stateData.locationPublicResources.inputUsageInstructions}</span>
                                                        </>
                                                    )}
                                                </span>
                                            )
                                        )}
                                    </div>
                                    {stateData.searchString && !stateData.selectedLocation && !stateData.showLocation && (
                                        <span className="location-error-message">
                                            {stateData.locationPublicResources.incorrectLocationLabelErrorString}
                                        </span>
                                    )}
                                    {stateData.searchString && (
                                        <button
                                            type="button"
                                            onClick={() => {
                                                this.setState({ searchString: '', showLocation: true, locationURL: '', selectedLocation: null, selectedLocationLabel: null, focusedLocationIndex: null, searchedArray: [] });
                                                this.locationInput.current.focus();
                                            }}
                                            aria-label="Clear Locations"
                                            data-modal="showLocation"
                                            className="DateRangePickerInput_clearDates"
                                        >
                                            <IoMdCloseCircleOutline />
                                        </button>
                                    )}
                                </div>
                                <div
                                    ref={this.locationDropdownDesktopRef}
                                    data-modal="showLocation"
                                    className={`locationDropdown ${stateData.showLocation === true ? 'active' : ''}`}
                                >
                                    {locationBlock}
                                </div>
                            </div>
                            <div
                                role="button"
                                ref={this.inputDateRangeRef}
                                className={`inputDateRangeOuter d-lg-block d-none ${stateData.focusedInput ? 'inputDateRangeOuter--shown' : ''} ${stateData.endDate ? 'inputDateRangeOuter--selected' : ''}`}
                                data-modal="showDateRange"
                                tabIndex={0}
                                aria-label="Date range input"
                                onClick={this.showDatePicker}
                                onFocus={this.handleFocus}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.showDatePicker();
                                    }
                                }}
                            >
                                <MdOutlineCalendarToday className="input-date-range__icon" />
                                {(fetchData.isHome || fetchData.isPillarPage) && (
                                    <div className="input-date-range__label">
                                        <strong>{stateData.staticPublicResources.desktop.datePickLabel}</strong>
                                        <br />
                                        {stateData.endDate
                                            ? `${moment(stateData.startDate).format('DD.MM')} - ${moment(stateData.endDate).format('DD.MM')}`
                                            : stateData.staticPublicResources.desktop.datePickLongLabel}
                                    </div>
                                )}
                                <input
                                    autoComplete="off"
                                    className={`inputDateRange ${stateData.showDateRange ? 'inputDateRange--shown' : ''} ${stateData.endDate ? 'inputDateRange--selected' : ''}`}
                                    name="dateRange"
                                    type="text"
                                    data-modal="showDateRange"
                                    placeholder={stateData.staticPublicResources.desktop.datePickLabel}
                                    value={stateData.endDate
                                        ? `${moment(stateData.startDate).format('DD.MM')} - ${moment(stateData.endDate).format('DD.MM')}`
                                        : ''}
                                    readOnly={true}
                                    hidden={fetchData.isHome || fetchData.isPillarPage}
                                />
                            </div>
                            {!stateData.isSmallDevice && (
                                <DateRangePicker
                                    displayFormat={() => 'DD.MM.YYYY'}
                                    startDate={stateData.startDate}
                                    startDateId="your_unique_start_date_id"
                                    endDate={stateData.endDate}
                                    endDateId="your_unique_end_date_id"
                                    onClose={() => this.setState({ showDateRange: false, shouldScrollToInitialMonth: true })}
                                    daySize={50}
                                    focusedInput={stateData.focusedInput}
                                    onDatesChange={({ startDate, endDate }) => {
                                        this.setState({ startDate, endDate });
                                        if (startDate && endDate) {
                                            this.setState({ focusedInput: START_DATE });
                                        }
                                        if (endDate) {
                                            const dayDiff = Math.ceil(endDate.diff(startDate) / (1000 * 60 * 60 * 24));
                                            if (dayDiff > 7) {
                                                this.setState({ flexibleDays: 7 });
                                            } else {
                                                this.setState({ flexibleDays: 1 });
                                            }
                                            this.confirmDates();
                                        }
                                        if (startDate === null && endDate === null) {
                                            this.setState({ flexibleDays: 1 });
                                        }
                                        if (stateData.focusedInput === START_DATE) {
                                            this.setState({ endDate: null });
                                        }
                                    }}
                                    onFocusChange={(focusedInput) => {
                                        this.setState({ focusedInput });
                                    }}
                                    initialVisibleMonth={() => stateData.calendarStartingMonth}
                                    orientation={orientation}
                                    numberOfMonths={numberOfMonths}
                                    keepOpenOnDateSelect={false}
                                    noBorder={true}
                                    readOnly={true}
                                    showClearDates={true}
                                    calendarInfoPosition="top"
                                    renderCalendarInfo={() => renderCalendarInfo}
                                    startDatePlaceholderText=""
                                    endDatePlaceholderText=""
                                    renderDayContents={(day) => renderDayInfo(day)}
                                    isDayHighlighted={(day) => day.day() % 6 === 0}
                                    isOutsideRange={(day) => day.isBefore(minDate, 'day') || day.isAfter(maxDate, 'day')}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    customCloseIcon={<IoMdCloseCircleOutline />}
                                    navPrev={<MdNavigateBefore />}
                                    navNext={<MdNavigateNext />}
                                />
                            )}
                            <div
                                ref={this.guestsInput}
                                className={`inputGuestsOuter d-lg-block d-none ${stateData.showGuests ? 'inputGuestsOuter--shown' : ''} ${stateData.persons > stateData.guestsPublicResources.adults.minimumNumber ? 'inputGuestsOuter--selected' : ''}`}
                                onFocus={this.handleFocus}
                                onBlur={this.handleBlurGuests}
                                data-modal="showGuests"
                                tabIndex={-1}
                            >
                                <div
                                    role="button"
                                    className="input-guests-inner"
                                    onFocus={this.handleFocus}
                                    data-modal="showGuests"
                                    tabIndex={0}
                                >
                                    <MdPersonOutline className="input-guests__icon" />
                                    {(fetchData.isHome || fetchData.isPillarPage) && (
                                        <div className="input-guests__label">
                                            <strong>{stateData.staticPublicResources.desktop.numberOfGuestsLabel}</strong>
                                            <br />
                                            {this.getSelectedGuestsLabel()}
                                        </div>
                                    )}
                                    <input
                                        autoComplete="off"
                                        className={`inputGuests ${stateData.showGuests ? 'inputGuests--shown' : ''} ${stateData.persons > stateData.guestsPublicResources.adults.minimumNumber ? 'inputGuests--selected' : ''}`}
                                        name="guests"
                                        type="text"
                                        data-modal="showGuests"
                                        placeholder={
                                            stateData.persons > stateData.guestsPublicResources.adults.minimumNumber && !fetchData.hide
                                                ? ` ${stateData.guestsPublicResources.selectedNumberOfGuestsLabel} ${stateData.persons}`
                                                : stateData.staticPublicResources.desktop.numberOfGuestsLabel
                                        }
                                        value={
                                            stateData.persons > stateData.guestsPublicResources.adults.minimumNumber
                                                ? ` ${stateData.guestsPublicResources.selectedNumberOfGuestsLabel} ${stateData.persons}`
                                                : ''
                                        }
                                        onChange={this.handleChange}
                                        readOnly={true}
                                        hidden={fetchData.isHome || fetchData.isPillarPage}
                                    />
                                    {stateData.persons > stateData.guestsPublicResources.adults.minimumNumber && (
                                        <button
                                            type="button"
                                            onClick={() => {
                                                this.setState({
                                                    persons: stateData.guestsPublicResources.adults.minimumNumber,
                                                    persons_adult: stateData.guestsPublicResources.adults.minimumNumber,
                                                    persons_0_3: 0,
                                                    persons_4_11: 0,
                                                    persons_12_17: 0,
                                                    p_dist: 0,
                                                    showGuests: true,
                                                });
                                                this.guestsInput.current.focus();
                                            }}
                                            aria-label="Clear Guests"
                                            data-modal="showGuests"
                                            className="DateRangePickerInput_clearDates"
                                        >
                                            <IoMdCloseCircleOutline />
                                        </button>
                                    )}
                                </div>
                                <div
                                    data-modal="showGuests"
                                    className={`guestsDropdown ${stateData.showGuests === true ? 'active' : ''}`}
                                >
                                    <p className="guestsIntroText">{stateData.guestsPublicResources.chooseLabel}</p>
                                    {guestsBlock('mobile')}
                                </div>
                            </div>
                            {fetchData && (
                                fetchData.isPillarPage ? (
                                    <button
                                        type="button"
                                        aria-label="Submit Search"
                                        className="form__button form__button--primary d-lg-flex d-none"
                                        disabled={stateData.searchString && !stateData.selectedLocation}
                                        onClick={() => {
                                            fetchData.update(
                                                this.prepareUpdatedParamsForPassingToUpperComponentFromThisComponentSearchParamsState(),
                                                true,
                                            );
                                            if (typeof zaraz !== 'undefined' && zaraz.consent) {
                                                const marketing = zaraz.consent.get('KlIi');
                                                if (zaraz.track && marketing) {
                                                    zaraz.track('searchAction', {
                                                        location_id: stateData.locationURL,
                                                        from: stateData.startDate ? moment(stateData.startDate).format('YYYY-MM-DD') : '',
                                                        to: stateData.endDate ? moment(stateData.endDate).format('YYYY-MM-DD') : '',
                                                        p_dist: stateData.p_dist,
                                                        persons_adult: stateData.persons_adult,
                                                        persons_0_3: stateData.persons_0_3,
                                                        persons_4_11: stateData.persons_4_11,
                                                        persons_12_17: stateData.persons_12_17,
                                                        deviation: stateData.isFlexible,
                                                    });
                                                    zaraz.track('PillarPageQuickSearchSubmit');
                                                }
                                            }
                                        }}
                                    >
                                        <MdOutlineSearch className="mainSearchFormAccordion__search-button__icon" />
                                        <span className="mainSearchFormAccordion__search-button__text">{stateData.staticPublicResources.mobile.searchLabel}</span>
                                    </button>
                                ) : (
                                    <button
                                        className="submitSearch d-lg-block d-none"
                                        type="button"
                                        aria-label="Submit Search"
                                        disabled={stateData.searchString && !stateData.selectedLocation}
                                        onClick={() => {
                                            fetchData.update(
                                                this.prepareUpdatedParamsForPassingToUpperComponentFromThisComponentSearchParamsState(),
                                                true,
                                            );
                                            if (typeof zaraz !== 'undefined' && zaraz.consent) {
                                                const marketing = zaraz.consent.get('KlIi');
                                                if (zaraz.track && marketing) {
                                                    zaraz.track('searchAction', {
                                                        location_id: stateData.locationURL,
                                                        from: stateData.startDate ? moment(stateData.startDate).format('YYYY-MM-DD') : '',
                                                        to: stateData.endDate ? moment(stateData.endDate).format('YYYY-MM-DD') : '',
                                                        p_dist: stateData.p_dist,
                                                        persons_adult: stateData.persons_adult,
                                                        persons_0_3: stateData.persons_0_3,
                                                        persons_4_11: stateData.persons_4_11,
                                                        persons_12_17: stateData.persons_12_17,
                                                        deviation: stateData.isFlexible,
                                                    });
                                                    if (fetchData.isHome) {
                                                        zaraz.track('HomepageQuickSearchSubmit');
                                                    } else {
                                                        zaraz.track('SearchResultsQuickSearchSubmit');
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        <MdOutlineSearch />
                                    </button>
                                )
                            )}
                        </form>
                    </div>
                )}
            </>
        );
    }
}
